// Imports
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// UI Imports
import Grid from "@material-ui/core/Grid/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconArrowBack from "@material-ui/icons/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import IconClose from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// App Imports
import params from "../../../setup/config/params";
import { nullToEmptyString } from "../../../setup/helpers";
import { list as listCategories } from "../../category/api/actions/query";
import { messageShow, multipleUpload, upload } from "../../common/api/actions";
import ImageComp from "../../common/Image";
import Loading from "../../common/Loading";
import SectionPaper from "../../common/SectionPaper";
import { listVendorsAll } from "../../user/api/actions/query";
import { createOrUpdate, productPurchase } from "../api/actions/mutation";
import { details } from "../api/actions/query";
import routes, { getImageSource } from "../api/routes";
import styles from "./styles";
import { detail } from "../../setting/api/actions/query";


// Component
class CreateOrUpdate extends Component {
  constructor(props) {
    super(props);
    this.settings = {
      onlinePricingPercentage: "",
    }

    this.product = {
      _id: "",
      vendorId: "",
      categoryId: "",
      name: "",
      slug: "",
      brand: "",
      description: "",
      price: "",
      priceDiscount: "",
      priceDiscounted: "",
      unit: "",
      openingStock: "",
      barcode: "",
      purchase: "",
      image: "default.jpg",
      gallery: [],
      canSubscribe: true,
      storePrice: "",
      isVeg:false,
      isNonVeg:false,
      isPublished:true,
      isFeatured:false,
      onlinepercentage:[],
      isLiveCounterItem:false,
      availability: {
        homeDelivery: false,
        storePickup: false
      }
    };

    this.state = {
      isLoading: false,
      isLoadingSubmit: false,
      isUploadingFile: false,
      purchaseCount: 0,
      vendors: [],
      product: this.product,
      purchaseShow: false,
      previewImage: null,
    };
    this.onChangeVegOrNonveg = this.onChangeVegOrNonveg.bind(this);
  }
  componentDidMount() {
    const {
      auth: { user },
      match: {
        params: { productId },
      },
    } = this.props;

    // Get categories
    this.getCategories();
    this.refresh();
    // Get vendors
    if (user.role === params.user.roles.vendor.key) {
      const { product } = this.state;
      product.vendorId = user._id;
      this.setState({
        product,
      });
    } else {
      this.getVendors();
    }

    // Get product for editing
    if (productId) {
      this.getProduct(productId);
    }
  }
  handleClose = () => this.setState({ purchaseCount: 0, purchaseShow: false });
  handleShow = () => this.setState({ purchaseCount: 0, purchaseShow: true });
  getVendors = async () => {
    const { listVendorsAll, messageShow } = this.props;

    try {
      const { data } = await listVendorsAll();

      if (data.success) {
        this.setState({
          vendors: data.data,
        });
      } else {
        messageShow(data.message);
      }
    } catch (error) {
      messageShow("There was some error. Please try again.");
    }
  };

  getCategories = () => {
    const { listCategories } = this.props;

    listCategories();
  };
  refresh = async () => {
    const { detail, messageShow } = this.props;
    try {
      const {
        match: {
          params: { vendorId },
        },
      } = this.props;
      
      this.setState({
        settings: { vendorId },
      });
      
      const { data } = await detail({ vendorId });
      
      if (data.success && data.data) {
        const { onlinePricingPercentage } = data.data; 
        this.setState({
          settings: {
            ...this.state.settings,
            onlinePricingPercentage, 
          },
          onlinepercentage: onlinePricingPercentage
        });
      }
    } catch (error) {
      messageShow("Some error occurred. Please try again.", error);
    }
  };


  getProduct = async (productId) => {
    const { details, messageShow } = this.props;

    this.isLoadingToggle(true);

    try {
      const { data } = await details({ productId });

      if (data.success) {
        let product = data.data.productDetails;
        let onlinepercentage = data.data.settingsData.onlinePricingPercentage; 

        product.vendorId = product.vendorId._id;
        product.categoryId = product.categoryId._id;

        this.setState({
          product,
          onlinepercentage
        });
      }
    } catch (error) {
      messageShow("There was some error. Please try again");
    } finally {
      this.isLoadingToggle(false);
    }
};

  onCreateOrUpdate = async (event) => {
    event.preventDefault();

    const { createOrUpdate, messageShow } = this.props;

    const { product } = this.state;

    this.isLoadingSubmitToggle(true);

    try {
      const { data } = await createOrUpdate({ product });

      this.isLoadingSubmitToggle(false);

      messageShow(data.message);

      if (data.success) {
        this.back();
      }
    } catch (error) {
      this.isLoadingSubmitToggle(false);

      messageShow("Some error occurred. Please try again.");
    }
  };

  isLoadingToggle = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  isLoadingSubmitToggle = (isLoadingSubmit) => {
    this.setState({
      isLoadingSubmit,
    });
  };

  isUploadingFileToggle = (isUploadingFile) => {
    this.setState({
      isUploadingFile,
    });
  };

  onTypePopup = ({ target: { value } }) => {
    this.setState({ purchaseCount: value });
  };

  updatePurchaseCount = async (event) => {
    let { product, purchaseCount } = this.state;
    const {
      auth: { user },
      match: {
        params: { productId },
      },
    } = this.props;
    product["purchase"] =
      (product["purchase"] ? product["purchase"] : 0) + +purchaseCount;
    this.setState({ purchaseShow: false, product: product });
    const { productPurchase } = this.props;
    await productPurchase({
      purchaseCount: purchaseCount,
      productId: productId,
    });
  };

  onType = ({ target: { name, value } }) => {
    const { product,onlinepercentage } = this.state;

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const slug = (string) => {
        return string.toLowerCase().replace(/ /g, '-');
    };

    const roundToNearestFiveCents = (num) => {
        return (Math.round(num * 20) / 20).toFixed(2);
    };

    if (name === "name") {
        product.slug = slug(value);
    }

    product[name] = name === "slug" ? value : capitalizeFirstLetter(value);

    if (name === "price" || name === "priceDiscount") {
        product.priceDiscounted =
            product.priceDiscount !== ""
                ? roundToNearestFiveCents(
                    product.price - (product.price * (product.priceDiscount / 100))
            )
          : product.price;
    }

    if (name === "storePrice" || name === "onlinepercentage") {
        product.price =
            onlinepercentage !== ""
                ? roundToNearestFiveCents(
                    parseFloat(product.storePrice) +
                    (parseFloat(product.storePrice) * (parseFloat(onlinepercentage) / 100))
                )
                : product.storePrice;

        product.priceDiscounted =
            product.priceDiscount !== ""
                ? roundToNearestFiveCents(
                    product.price - (product.price * (product.priceDiscount / 100))
                )
          : product.price;   
          
    }

    this.setState({
      product,
    });
  };

  onSelect = ({ target: { name, value } }) => {
    const { product } = this.state;

    product[name] = value;

    this.setState({
      product,
    });
  };

  onUpload = async (event) => {
    const { upload, messageShow } = this.props;

    messageShow("Uploading file, please wait...");

    this.isUploadingFileToggle(true);

    const imgFile = event.target.files[0];
    const file = new FormData();
    file.append("type", "product");
    file.append("name", this.state.product.slug);
    file.append("file", event.target.files[0]);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const fileResult = fileReader.result;
      let dataAsImg = new Image();
      dataAsImg.src = fileResult;
      this.setState({ previewImage: dataAsImg.src });
    };
    fileReader.readAsDataURL(imgFile);

    // Upload image
    try {
      const { data } = await upload(file);

      if (data.success) {
        messageShow("File uploaded successfully.");

        const { product } = this.state;

        setTimeout(() => {
          product.image = data.file;
          this.setState({
            product,
          });
        }, 1500);
      } else {
        messageShow("There was some error. Please try again.");
      }
    } catch (error) {
      messageShow("There was some error. Please try again.");
    } finally {
      this.isUploadingFileToggle(false);
    }
  };

  onUploadGallery = async (event) => {
    const { multipleUpload, messageShow } = this.props;

    messageShow("Uploading file, please wait...");

    this.isUploadingFileToggle(true);

    var formdata = new FormData();
    formdata.append("type", "product");

    for (let i = 0; i < Object.keys(event.target.files).length; i++) {
      let file = event.target.files[i];
      formdata.append(`files`, file, file.name);
    }

    // Upload image
    try {
      const { data } = await multipleUpload(formdata);

      if (data.success) {
        messageShow("File uploaded successfully.");

        const { product } = this.state;
        setTimeout(() => {
          product.gallery = data.file;
          this.setState({
            product,
          });
        }, 1500);
      } else {
        messageShow("There was some error. Please try again.");
      }
    } catch (error) {
      messageShow("There was some error. Please try again.");
    } finally {
      this.isUploadingFileToggle(false);
    }
  };

  back = () => {
    const { history } = this.props;
    history.push(routes.productList.path(1));
    /*if (history.length > 2) {
      history.goBack();
    } else {
      history.push(routes.productList.path(1));
    }*/
  };
  onChange = ({ target: { name, value } }) => {
    const { product } = this.state;
    product[name] = value;
    this.setState({
      product,
    });
  };

  onChangefeature = (event) => {
    const { name, checked } = event.target;
    this.setState((prevState) => ({
      product: {
        ...prevState.product,
        [name]: checked,
      },
    }));
  };

 
  onChangeLiveCounter = (event) => {
    const isLiveCounterItem = event.target.checked;
    this.setState(prevState => {
      const newAvailability = isLiveCounterItem 
        ? { ...prevState.product.availability, storePickup: true } 
        : { storePickup: false, homeDelivery: false };

      return {
        product: {
          ...prevState.product,
          isLiveCounterItem,
          availability: newAvailability
        }
      };
    });
  };

  onChangeAvailability = (event) => {
    const { name, checked } = event.target;
    this.setState(prevState => {
      const newAvailability = {
        ...prevState.product.availability,
        [name]: checked
      };
      if (prevState.product.isLiveCounterItem) {
        const otherName = name === "storePickup" ? "homeDelivery" : "storePickup";
        if (!checked && !prevState.product.availability[otherName]) {
          alert("Atleast one delivery method must be selected when Live Counter Product is enabled.");
          return prevState;
        }
      }
      return {
        product: {
          ...prevState.product,
          availability: newAvailability
        }
      };
    });
  };
  

  onChangeCanSubscribe = (event) => {
    const { product } = this.state;

    product.canSubscribe = event.target.checked;

    this.setState({
      product,
    });
  };

 onChangeVegOrNonveg(event) {
  const { name, checked } = event.target;
  this.setState((prevState) => ({
    product: {
      ...prevState.product,
      isVeg: name === "isVeg" ? checked : !checked,
      isNonVeg: name === "isNonVeg" ? checked : !checked,
    },
  }));
}

  render() {
    const {
      auth: { user },
      match: {
        params: { productId },
      },
      categories,
      classes,
    } = this.props;
    const { vendors, product, isLoading, isLoadingSubmit, isUploadingFile } =
      this.state;

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={this.back}
          >
            <IconArrowBack />
          </IconButton>

          <Typography variant="h6" color="inherit" className={classes.grow}>
            {productId ? "Edit" : "Create"} Product
          </Typography>
        </Toolbar>

        <Grid item xs={12} lg={6}>
          <SectionPaper padding>
            {isLoading ? (
              <Loading />
            ) : (
              <form onSubmit={this.onCreateOrUpdate}>
                {/* Input - Name */}
                <Grid item xs={12}>
                  <TextField
                    name={"name"}
                    value={nullToEmptyString(product.name)}
                    onChange={this.onType}
                    label={"Name"}
                    placeholder={"Enter product name"}
                    required={true}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                    autoFocus
                  />
                </Grid>

                {/* Input - Slug */}
                <Grid item xs={12}>
                  <TextField
                    name={"slug"}
                    value={nullToEmptyString(product.slug)}
                    onChange={this.onType}
                    label={"Slug"}
                    placeholder={"Enter slug"}
                    required={true}
                    margin={"normal"}
                    autoComplete={"off"}
                    fullWidth
                  />
                </Grid>

                {/* Input - Brand */}
                <Grid item xs={12}>
                  <TextField
                    name={"brand"}
                    value={nullToEmptyString(product.brand)}
                    onChange={this.onType}
                    label={"Brand"}
                    placeholder={"Enter brand name"}
                    required={true}
                    margin={"normal"}
                    autoComplete={"off"}
                    fullWidth
                  />
                </Grid>

                {/* Input - Vendors */}
                {user.role === params.user.roles.admin.key && (
                  <FormControl
                    fullWidth
                    className={classes.select}
                    required={true}
                  >
                    <InputLabel htmlFor="vendorId">Vendor</InputLabel>
                    <Select
                      value={nullToEmptyString(product.vendorId)}
                      onChange={this.onSelect}
                      inputProps={{
                        name: "vendorId",
                        id: "vendorId",
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {vendors.length > 0 &&
                        vendors.map((item) => (
                          <MenuItem
                            key={item._id}
                            value={item._id}
                          >{`${item.name} (${item.email})`}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}

                {/* Input - Categories */}
                <FormControl
                  fullWidth
                  className={classes.select}
                  required={true}
                >
                  <InputLabel htmlFor="categoryId">Category</InputLabel>
                  <Select
                    value={nullToEmptyString(product.categoryId)}
                    onChange={this.onSelect}
                    inputProps={{
                      name: "categoryId",
                      id: "categoryId",
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {categories.isLoading ? (
                      <MenuItem disabled>
                        <em>loading</em>
                      </MenuItem>
                    ) : (
                      categories.list.map(({ category, subCategories }) => [
                        <MenuItem
                          key={category._id}
                          value={category._id}
                          disabled
                        >
                          {category.name}
                        </MenuItem>,
                        subCategories.map((subCategory) => (
                          <MenuItem
                            key={subCategory._id}
                            value={subCategory._id}
                            style={{ paddingLeft: 40 }}
                          >
                            {subCategory.name}
                          </MenuItem>
                        )),
                      ])
                    )}
                  </Select>
                </FormControl>

                {/* Input - Description */}
                <Grid item xs={12}>
                  <TextField
                    name={"description"}
                    value={nullToEmptyString(product.description)}
                    onChange={this.onType}
                    label={"Description"}
                    placeholder={"Enter description"}
                    margin={"dense"}
                    autoComplete={"off"}
                    required={true}
                    multiline
                    rows={3}
                    fullWidth
                  />
                </Grid>
                {/* veg or nonveg */}
                <b> Veg Or NonVeg </b>

                <Grid container spacing={12}>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                        checked={product.isVeg}
                        onChange={this.onChangeVegOrNonveg}
                        name="isVeg"
                        color="primary"
                        margin={"normal"}
                        autoComplete={"off"}
                        fullWidth
                        />
                      }
                      label="Veg"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                        checked={product.isNonVeg}
                        onChange={this.onChangeVegOrNonveg}
                        name="isNonVeg"
                        color="primary"
                        margin={"normal"}
                        autoComplete={"off"}
                        fullWidth
                        />
                      }
                      label="Non Veg"
                    />
                  </Grid>
                </Grid>
                 {/* publish and featured */}
                 
                <Grid container spacing={12}>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                        checked={product.isPublished}
                        onChange={this.onChangefeature}
                        name="isPublished"
                        color="primary"
                        margin={"normal"}
                        autoComplete={"off"}
                        fullWidth
                        />
                      }
                      label="Published"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                        checked={product.isFeatured}
                        onChange={this.onChangefeature}
                        name="isFeatured"
                        color="primary"
                        margin={"normal"}
                        autoComplete={"off"}
                        fullWidth
                        />
                      }
                      label="Featured"
                    />
                  </Grid>
                </Grid>


                <b> Live Counter Item </b>

                <Grid container spacing={12}>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={product.isLiveCounterItem}
                          onChange={this.onChangeLiveCounter}
                          name="isLiveCounterItem"
                          color="primary"
                          margin="normal"
                          autoComplete="off"
                          fullWidth
                        />
                      }
                      label="Enable Live Counter"
                    />
                  </Grid>
                </Grid>
                  <Grid container spacing={12}>
                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                          checked={product.availability.storePickup}
                          onChange={this.onChangeAvailability}
                          name="storePickup"
                          color="primary"
                          margin={"normal"}
                          autoComplete={"off"}
                          disabled={
                              !product.isLiveCounterItem
                             }
                          fullWidth
                        />
                      }
                      label="Pickup At Store"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={product.availability.homeDelivery}
                          onChange={this.onChangeAvailability}
                          name="homeDelivery"
                          color="primary"
                          margin={"normal"}
                          autoComplete={"off"}
                          disabled={
                             !product.isLiveCounterItem
                          }
                          fullWidth
                        />
                      }
                      label="Home Delivery"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={8}>
                {/* store price  */}
                <Grid item xs={4}>
                    <TextField
                      type={"number"}
                      name={"storePrice"}
                      value={nullToEmptyString(product.storePrice)}
                      onChange={this.onType}
                      label={"Store Price"}
                      placeholder={"Enter Store price "}
                      required={true}
                      margin={"dense"}
                      autoComplete={"off"}
                      fullWidth
                    />
                  </Grid>
                  {/* Input - Price */}
                  <Grid item xs={4}>
                    <TextField
                      type={"number"}
                      name={"price"}
                      value={nullToEmptyString(product.price)}
                      onChange={this.onType}
                      label={"Online Price"}
                      placeholder={"Enter Online price"}
                      required={true}
                      margin={"dense"}
                      autoComplete={"off"}
                      fullWidth
                    />
                  </Grid>
                  </Grid>
                  <Grid container spacing={8}>

                  {/* Input - Discount */}
                  <Grid item xs={4}>
                    <TextField
                      type={"number"}
                      name={"priceDiscount"}
                      value={nullToEmptyString(product.priceDiscount)}
                      onChange={this.onType}
                      label={"Discount in %"}
                      placeholder={"Enter discount in %"}
                      margin={"dense"}
                      autoComplete={"off"}
                      fullWidth
                    />
                  </Grid>

                  {/* Input - Discounted price */}
                  <Grid item xs={4}>
                    <TextField
                      type={"number"}
                      name={"priceDiscounted"}
                      value={nullToEmptyString(product.priceDiscounted)}
                      label={"Discounted Price"}
                      margin={"dense"}
                      autoComplete={"off"}
                      disabled
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={10} className={classes.buttonUpload}>
                  <Grid item xs={7}>
                    {/* Input - Unit */}
                    <TextField
                      name={"unit"}
                      value={nullToEmptyString(product.unit)}
                      onChange={this.onType}
                      label={"Unit"}
                      placeholder={"Enter unit (eg: kg)"}
                      required={true}
                      margin={"dense"}
                      autoComplete={"off"}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={4}>
                    {/* Input - Can Subscribe */}
                    {/* <FormControlLabel
                        control={
                          <Checkbox
                            checked={product.canSubscribe}
                            onChange={this.onChangeCanSubscribe}
                            color='primary'
                          />
                        }
                        label='Subscribable'
                      /> */}
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  {/* Input - openingStock */}
                  <TextField
                    name={"openingStock"}
                    value={nullToEmptyString(product.openingStock)}
                    onChange={this.onType}
                    label={"Opening Stock"}
                    placeholder={"Enter Opening Stock (eg: 10)"}
                    required={true}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                    disabled={productId ? true : false}
                  />
                </Grid>
                <Grid item xs={12}>
                  {/* Input - Current Stock */}
                  <TextField
                    name={"currentStock"}
                    value={nullToEmptyString(product.currentStock)}
                    onChange={this.onType}
                    label={"Current Stock"}
                    placeholder={"Enter Current Stock (eg: 10)"}
                    required={true}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                    disabled={productId ? true : false}
                  />
                </Grid>
                <Grid item xs={7}>
                  {/* Input - Barcode */}
                  {/* <TextField
                      name={'barcode'}
                      value={nullToEmptyString(product.barcode)}
                      onChange={this.onType}
                      label={'Barcode'}
                      placeholder={'Enter QR code or barcode'}
                      required={true}
                      margin={'dense'}
                      autoComplete={'off'}
                      fullWidth
                    /> */}
                </Grid>
                {/*  <Grid item xs={4}>
                    <QRCode value={product.barcode} size={100} />
                  </Grid> */}
                {productId ? (
                  <Grid container spacing={10} className={classes.buttonUpload}>
                    <Grid item xs={7}>
                      {/* Input - Unit */}
                      <TextField
                        name={"purchase"}
                        value={nullToEmptyString(product.purchase)}
                        onChange={this.onType}
                        label={"Added Stock"}
                        placeholder={"Added Stock (eg: 100)"}
                        required={true}
                        margin={"dense"}
                        autoComplete={"off"}
                        disabled={true}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      {/* Input - Can Subscribe */}
                      <Button variant="primary" onClick={this.handleShow}>
                        Restock
                      </Button>
                    </Grid>
                  </Grid>
                ) : null}

                {/* Input - Image */}
                <Grid container spacing={10} className={classes.buttonUpload}>
                  <Grid item sm={6}>
                    <div style={{ marginBottom: 20 }}>
                      <input
                        accept={"image/png,image/jpeg"}
                        style={{ display: "none" }}
                        id={"contained-button-file"}
                        type={"file"}
                        onChange={this.onUpload}
                        disabled={!product.slug}
                      />

                      <label htmlFor={"contained-button-file"}>
                        <Button
                          variant={"outlined"}
                          component={"span"}
                          type={"file"}
                          disabled={isUploadingFile || !product.slug}
                          fullWidth
                        >
                          Upload Primary Image
                        </Button>
                      </label>
                    </div>
                    {!this.state.previewImage ? (
                      <ImageComp
                        src={getImageSource(`${product.image}`)}
                        defaultSrc={getImageSource()}
                        size={150}
                      />
                    ) : (
                      <img
                        height="auto"
                        width="100%"
                        src={this.state.previewImage}
                        alt="preview_category"
                      ></img>
                    )}
                  </Grid>

                  {/* Gallery */}
                  {/* <Grid item sm={6}>
                      <div style={{ marginBottom: 20 }}>
                        <input
                          accept={'image/png,image/jpeg'}
                          style={{ display: 'none' }}
                          id={'contained-button-file-gallery'}
                          type={'file'}
                          multiple
                          onChange={this.onUploadGallery}
                        />

                        <label htmlFor={'contained-button-file-gallery'}>
                          <Button
                            variant={'outlined'}
                            component={'span'}
                            type={'file'}
                            disabled={isUploadingFile}
                            fullWidth
                          >
                            Upload Gallery Image
                            </Button>
                        </label>
                      </div>

                      {
                        product.gallery.length === 0
                          ? <div><EmptyMessage message='Gallery is empty.' /></div>
                          : <div>
                            {
                              product.gallery.map((image, i) =>
                                <a key={i} href={image} target={'_blank'} style={{ marginRight: 10, marginBottom: 10, display: 'inline-block' }}>
                                  <ImageComp
                                    src={image}
                                    defaultSrc={getImageSource()}
                                    size={50}
                                  />
                                </a>
                              )
                            }
                          </div>
                      }
                    </Grid> */}
                </Grid>

                {/* Button - Save */}
                <Grid item xs={12} className={classes.buttonsContainer}>
                  <IconButton
                    type={"button"}
                    aria-label={"Close"}
                    onClick={this.back}
                  >
                    <IconClose />
                  </IconButton>

                  <IconButton
                    type={"submit"}
                    aria-label={"Save"}
                    color={"primary"}
                    disabled={isLoadingSubmit}
                  >
                    <CheckIcon />
                  </IconButton>
                </Grid>
              </form>
            )}
            <Dialog
              onClose={this.handleClose}
              open={this.state.purchaseShow}
              aria-labelledby="simple-dialog-title"
              fullWidth={true}
            >
              <DialogTitle id="simple-dialog-title">
                Purchase Addition
              </DialogTitle>
              <DialogContent>
                <div>
                  <Grid container spacing={10} className={classes.buttonUpload}>
                    <Grid item xs={7}>
                      {/* Input - Unit */}
                      <TextField
                        name={"purchase"}
                        value={this.state.purchaseCount}
                        onChange={this.onTypePopup}
                        label={"Added stock"}
                        placeholder={"Purchase Count (eg: 100)"}
                        required={true}
                        type="number"
                        margin={"dense"}
                        autoComplete={"off"}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      {/* Input - Can Subscribe */}
                      <Button
                        variant="primary"
                        onClick={this.updatePurchaseCount}
                      >
                       Add Stock
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </DialogContent>
            </Dialog>
          </SectionPaper>
        </Grid>
      </div>
    );
  }
}

// Component Properties
CreateOrUpdate.propTypes = {
  auth: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
  details: PropTypes.func.isRequired,
  detail: PropTypes.func.isRequired,
  createOrUpdate: PropTypes.func.isRequired,
  listVendorsAll: PropTypes.func.isRequired,
  listCategories: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

// Component State
function createOrEditState(state) {
  return {
    auth: state.auth,
    categories: state.categories,
  };
}

export default connect(createOrEditState, {
  details,
  detail,
  createOrUpdate,
  productPurchase,
  listVendorsAll,
  listCategories,
  upload,
  multipleUpload,
  messageShow,
})(withStyles(styles)(CreateOrUpdate));
