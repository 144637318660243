// App Imports
import {IMAGE_SOURCE_PATH } from '../../../setup/config/env'
import params from '../../../setup/config/params'
import List from '../List'
import creategoals from '../Creategoals'
import UpdateGoals from '../UpdateGoals'
import createrewards from '../Createrewards'
import UpdateRewards from '../UpdateRewards'
import LeaderboardDetail from '../Leaderboard/Detail'
import createEvents from '../CreateEvents'
import UpdateEvents from '../UpdateEvents'



// Image
export function getImageSource(image = '/default.jpg') {
  return `${ IMAGE_SOURCE_PATH }${ image }`
}

// Rewards and Goals routes
export default {
  listAllTabs: {
    path: '/goals',
    component: List,
    exact: true,
    auth: true,
    role: params.user.roles.admin.key
  },
   listGoals: {
    path: '/goals',
    component: List,
    exact: true,
    auth: true,
    role: params.user.roles.admin.key
  },
  creategoals: {
    path: '/goals/create',
    component: creategoals,
    auth: true,
    role: params.user.roles.admin.key
  },
  editGoals: {
    path: (goalId = ':goalId') => (`/goals/edit/${ goalId }`),
    component: UpdateGoals,
    auth: true,
    role: params.user.roles.admin.key
  },
  listPoints: {
    path: '/points',
    component: List,
    exact: true,
    auth: true,
    role: params.user.roles.admin.key
  },
  listRewards: {
    path: '/rewards',
    component: List,
    exact: true,
    auth: true,
    role: params.user.roles.admin.key
  },

  createRewards: {
    path: '/rewards/create',
    component: createrewards,
    auth: true,
    role: params.user.roles.admin.key
  },

  editRewards: {
    path: (rewardsId = ':rewardsId') => (`/rewards/edit/${rewardsId }`),
    component: UpdateRewards,
    auth: true,
    role: params.user.roles.admin.key
  },

  listLeaderboard: {
    path: '/leaderboard',
    component: List,
    exact: true,
    auth: true,
    role: params.user.roles.admin.key
  },

  LeaderBoardDetails: {
    path: (_id = ':_id') => (`/leaderboard/detail/${_id }`),
    component: LeaderboardDetail,
    auth: true,
    role:[ params.user.roles.admin.key , params.user.roles.vendor.key]
  },
  createEvents: {
    path: '/events/create',
    component: createEvents,
    auth: true,
    role: params.user.roles.admin.key
  },
  editEvents: {
    path: (eventId = ':eventId') => (`/events/update/${ eventId }`),
    component: UpdateEvents,
    auth: true,
    role: params.user.roles.admin.key
  },
}
