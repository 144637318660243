// Imports
import { withStyles } from '@material-ui/core'
import Button from '@mui/material/Button'
import Checkbox from "@mui/material/Checkbox"
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
// UI Imports
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from "@mui/icons-material/Add";
import dateFormat from 'date-fns/format'
import dateParseISO from 'date-fns/parseISO'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
// App Imports
import params from '../../../setup/config/params'
import { messageShow } from '../../common/api/actions'
import EmptyMessage from '../../common/EmptyMessage'
import Loading from '../../common/Loading'
import SectionPaper from '../../common/SectionPaper'
import { publishToggle, remove } from '../api/actions/mutation'
import { list } from '../api/actions/query'
import routes from '../api/routes'
import styles from './styles'



// Component
class List extends PureComponent {

  componentDidMount() {
    this.refresh()
  }

  refresh = async () => {
    const { list } = this.props

    await list()
  }

  onDelete = (pincodeId) => async () => {
    let check = window.confirm('Are you sure you want to delete this pincode?')

    if (check) {
      const { remove, list, messageShow } = this.props

      try {
        const { data } = await remove({ pincodeId })

        messageShow(data.message)

        if (data.success) {
          list()
        }
      } catch (error) {
        messageShow('Some error occurred. Please try again.')
      }
    }
  }

  onPublishToggle = pincodeId => async event => {
    const isPublished = event.target.checked

    let check = window.confirm(`Are you sure you want to ${isPublished ? 'publish' : 'unpublish'} this pincode?`)

    if (check) {
      const { publishToggle, messageShow } = this.props

      try {
        const { data } = await publishToggle({ pincodeId, isPublished })

        messageShow(data.message)

        if (data.success) {
          this.refresh()
        }
      } catch (error) {
        messageShow('Some error occurred. Please try again.')
      }
    }
  }

  render() {
    const { pincodes: { isLoading, list }, classes } = this.props

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            Pincodes
          </Typography>

          <Link to={routes.pincodeCreate.path}>
           <Tooltip title="Create Pincode" arrow>
            <Button size='big' color="inherit" startIcon={<AddIcon />}></Button>
           </Tooltip>
          </Link>
        </Toolbar>

        <SectionPaper>
          {
            isLoading
              ? <Loading />
              : list.length === 0
                ? <EmptyMessage message={'You have not added any pincodes yet.'} />
                : <Table padding={"normal"}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Pincode</TableCell>
                      <TableCell align="center">Areas</TableCell>
                      <TableCell >Updated</TableCell>
                      <TableCell align="center">Published</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {
                      list.map(({ _id, pincode, isPublished, updatedAt }) =>
                        <TableRow key={_id} onClick={() => window.location.href = `/pincode/edit/${_id}`} style={{ cursor: 'pointer' }} hover>
                          <TableCell>{pincode}</TableCell>
                          <TableCell align="center">
                            <Button
                              onClick={(e) => e.stopPropagation()} 
                              component={Link}
                              variant="outlined"
                              color="secondary" 
                              to={routes.pincodeManage.path(_id)}
                              style={{ borderRadius: '5px',
                              textTransform: 'none',  
                              padding: '1px 15px',     
                              fontSize: '0.8rem', 
                              }}
                            >
                              Manage
                            </Button>
                          </TableCell>
                          <TableCell>{dateFormat(dateParseISO(updatedAt), params.date.format.full)}</TableCell>
                          <TableCell align="center">
                            <Checkbox
                              onClick={(e) => e.stopPropagation()}
                              checked={isPublished}
                              onChange={this.onPublishToggle(_id)}
                              color="primary"
                            />
                          </TableCell>
                          <TableCell align="center">
                            <Tooltip title="Delete" arrow>
                              <Button color='error'onClick={(e) => { e.stopPropagation(); this.onDelete(_id)(); }} ><DeleteIcon /></Button>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      )
                    }
                  </TableBody>
                </Table>
          }
        </SectionPaper>
      </div>
    )
  }
}

// Component Properties
List.propTypes = {
  pincodes: PropTypes.object.isRequired,
  list: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  publishToggle: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

// Component State
function listState(state) {
  return {
    pincodes: state.pincodes
  }
}

export default connect(listState, { list, remove, publishToggle, messageShow })(withStyles(styles)(List))
