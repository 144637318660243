// Imports
import { withStyles } from "@material-ui/core";
import Button from '@mui/material/Button'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { messageShow, upload } from '../../common/api/actions'
import SectionPaper from '../../common/SectionPaper'
import PropTypes from "prop-types";
import { getImageSource } from "../apiRewards/routes";
import ImageComp from '../../common/Image';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import {
  nullToEmptyString,
} from "../../../setup/helpers";
import routes from '../api/routes'
import styles from './styles'
import { getrewards } from '../apiRewards/actions/query'
import { updaterewards } from '../apiRewards/actions/mutation'
import { listCategories, productlistByCategory } from '../api/actions/query';

// UI Imports
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconArrowBack from "@material-ui/icons/ArrowBack";
import FileUpload from "@mui/icons-material/FileUpload";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { getPoints } from "../../rewards/apiPoints/actions/query";
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import InputLabel from "@mui/material/InputLabel";
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { Select, MenuItem, InputAdornment } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete } from "@mui/material";

// Component
class updateRewards extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      dynamicPoints: '',
      dynamicValues: '',
      isLoading: false,
      isLoadingSubmit: false,
      isUploadingFile: false,
      rewards: {
        rewardName: "",
        earnedPoints: "",
        earnedValues: "",
        offlineDeals: false,
        onlineDeals: {
          discountPercentage: "" || 0,
          minOrderAmount: "" || 0,
          maxDiscountAmount: "" || 0
        },
        category: "",
        activeProducts: [],
        voucherCode: "",
        issueVolume: "",
        startDate: '',
        expiryDate: '',
        startTime: '',
        endTime: '',
        image: "default.jpg",
      },
      timeSelection: '',
      categories: [],
      subCategoryProducts: {},
      selectedSubCategory: null,
      selectedSubCategoryName: "",
      searchTerm: "",
      selectedCategories: [],  
      isCategorySelected: false, 
      previousCategory: "",  
      activeProductsByCategory: {},
      showAllProducts: false, 
      showAllSubCategories: false,
    };
  }

  componentDidMount() {
    this.refresh();
    this.refreshPoints();
    this.loadActiveProducts();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.subCategoryProducts !== this.state.subCategoryProducts) {
    }
  }


  loadActiveProducts = async () => {
    const { listCategories } = this.props;
    try {
      const response = await listCategories();
      if (response && response.data) {
        const { data } = response;
        if (data.success && Array.isArray(data.data)) {
          const categories = data.data.map(categoryData => {
            const categoryName = categoryData.category.name;
            const subCategoryNames = categoryData.subCategories.map(subCat => ({
              _id: subCat._id,
              name: subCat.name,
            }));

            return {
              _id: categoryData.category._id,
              name: categoryName,
              subCategories: subCategoryNames,
            };
          });
          this.setState({ categories }, () => {
            this.loadProductsForCategory();
          });
        }
      }
    } catch (error) {
      console.error("Error fetching categories", error);
    }
  }

  loadProductsForCategory = () => {
    const { rewards, categories } = this.state;
    if (rewards.category) {
      const category = categories.find(cat =>
        cat.subCategories.some(sub => sub.name === rewards.category)
      );

      if (category) {
        const subCategory = category.subCategories.find(sub => sub.name === rewards.category);
        if (subCategory) {
          this.handleSubCategorySelect(subCategory._id, subCategory.name);
        }
      }
    }
  };

  handleSubCategorySelect = async (subCategoryId, subCategoryName) => {
    const { rewards } = this.state;

    this.setState({
      previousCategory: rewards.category,
      selectedSubCategory: subCategoryId,
      selectedSubCategoryName: subCategoryName,
      rewards: {
        ...this.state.rewards,
        ...rewards,
        category: subCategoryName,
      },
      searchTerm: "",
      activeProducts: [],
      isCategorySelected: false,
    });

    const { productlistByCategory } = this.props;
    try {
      const response = await productlistByCategory(subCategoryId);
      if (response && response.data && response.data.success) {
        const products = response.data.data;

        this.setState((prevState) => ({
          subCategoryProducts: {
            ...prevState.subCategoryProducts,
            [subCategoryId]: products,
          },
        }));
      } else {
        console.error("Unexpected response structure:", response);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  handleProductSelect = (selectedProducts) => {
    const selectedProductIds = selectedProducts.map(product =>
      typeof product === 'object' && product !== null ? product._id : product
    );

    const selectedSubCategories = selectedProductIds.reduce((acc, productId) => {
      for (let subCategoryId in this.state.subCategoryProducts) {
        const product = this.state.subCategoryProducts[subCategoryId].find(p => p._id === productId);
        if (product) {
          const subCategoryName = this.state.categories
            .flatMap(cat => cat.subCategories)
            .find(subCat => subCat._id === subCategoryId).name;
          if (!acc.includes(subCategoryName)) {
            acc.push(subCategoryName);
          }
        }
      }
      return acc;
    }, []);

    this.setState((prevState) => ({
      rewards: {
        ...prevState.rewards,
        activeProducts: selectedProductIds,
        category: selectedSubCategories.join(", "),
      },
      isCategorySelected: true,
    }));
  };
  handleActiveProductsDropdownClose = () => {
    const { isCategorySelected, previousCategory, rewards } = this.state;

    // Check if no products were selected and revert to the previously saved category
    if (!isCategorySelected) {
      this.setState({
        rewards: {
          ...rewards,
          category: previousCategory,  // Reset to previously saved category
        },
      });
    } else {
      this.setState({ isCategorySelected: false });  // Reset the category selection flag
    }
  };
  
  handleSearchChange = (event) => {
    this.setState({ searchTerm: event.target.value });
  };
  handleDeleteProduct = (productId) => {
    const { rewards, subCategoryProducts, categories } = this.state;

    const updatedActiveProducts = rewards.activeProducts.filter(id => id !== productId);

    let productCategoryName = '';
    let productSubCategoryId = '';
    for (let subCategoryId in subCategoryProducts) {
      const product = subCategoryProducts[subCategoryId].find(p => p._id === productId);
      if (product) {
        const subCategory = categories
          .flatMap(cat => cat.subCategories)
          .find(subCat => subCat._id === subCategoryId);

        if (subCategory) {
          productCategoryName = subCategory.name;
          productSubCategoryId = subCategoryId;
        }
        break;
      }
    }
    const remainingProductsInSubCategory = subCategoryProducts[productSubCategoryId]?.some(product =>
      updatedActiveProducts.includes(product._id)
    );

    const updatedCategories = remainingProductsInSubCategory
      ? rewards.category.split(", ")
      : rewards.category.split(", ").filter(cat => cat !== productCategoryName);

    const updatedCategory = updatedCategories.join(", ");

    this.setState(prevState => ({
      rewards: {
        ...prevState.rewards,
        activeProducts: updatedActiveProducts,
        category: updatedCategory,
      },
    }));
  };

  refreshPoints = async () => {
    const { getPoints, messageShow } = this.props;

    this.isLoadingToggle(true);

    try {
      const { data } = await getPoints();
      if (data.success && data.data) {
        this.setState({
          dynamicPoints: data.data[0].points,
          dynamicValues: data.data[0].value
        });
      }
    } catch (error) {
      console.log(error);
      messageShow("Some error occurred. Please try again.", error);
    } finally {
      this.isLoadingToggle(false);
    }
  };

  refresh = async () => {
    const { getrewards, isLoading, match: { params: { rewardsId } } } = this.props;
    this.isLoadingToggle(isLoading);

    try {
      const { data } = await getrewards({ "id": rewardsId });
      if (data.success) {
        const timeSelection = data.data.startTime === '00:00' && data.data.endTime === '23:59' ? '24-hour' : 'custom';
        this.setState({
          rewards: data.data,
          timeSelection: timeSelection,
        }, () => {
          this.loadProductsForCategory();
        });
      } else {
        messageShow(data.message);
      }
    } catch (error) {
      messageShow('There was some error. Please try again.', error);
    } finally {
      this.isLoadingToggle(false);
    }
  };

  isLoadingSubmitToggle = (isLoadingSubmit) => {
    this.setState({
      isLoadingSubmit,
    });
  };
  isUploadingFileToggle = (isUploadingFile) => {
    this.setState({
      isUploadingFile,
    });
  };

  handleDateChange = (field) => (newDate) => {
    if (newDate) {
      const formattedDate = dayjs(newDate).format('YYYY-MM-DD');
      this.setState((prevState) => ({
        rewards: {
          ...prevState.rewards,
          [field]: formattedDate,
        },
      }));
    }
  };

  handleTimeChange = (time, key) => {
    const formattedTime = dayjs(time).format('HH:mm');

    if (key === 'startTime' && this.state.timeSelection === 'custom') {
      const endTime = dayjs(time).add(1, 'hour').format('HH:mm');
      this.setState((prevState) => ({
        rewards: {
          ...prevState.rewards,
          startTime: formattedTime,
          endTime,
        },
      }));
    } else {
      this.setState((prevState) => ({
        rewards: {
          ...prevState.rewards,
          [key]: formattedTime,
        },
      }));
    }
  };

  handleTimeSelectionChange = (event) => {
    const { value } = event.target;
    this.setState({ timeSelection: value });

    if (value === '24-hour') {
      this.setState({
        rewards: {
          ...this.state.rewards,
          startTime: '00:00',
          endTime: '23:59',
        },
      });
    } else {
      this.setState({
        rewards: {
          ...this.state.rewards,
          startTime: '',
          endTime: '',
        },
      });
    }
  };

  handleEarnedPointsChange = (event) => {
    const points = parseFloat(event.target.value);
    if (!isNaN(points)) {
      const { dynamicPoints, dynamicValues } = this.state;
      const calculatedValue = (points * dynamicValues) / dynamicPoints;
      this.setState({
        rewards: {
          ...this.state.rewards,
          earnedPoints: points,
          earnedValues: calculatedValue.toFixed(2),
        }
      });
    } else {
      this.setState({
        rewards: {
          ...this.state.rewards,
          earnedPoints: '',
          earnedValues: '',
        }
      });
    }
  };

  onUpload = async (event) => {
    const { upload, messageShow } = this.props;

    messageShow("Uploading file, please wait...");

    this.isUploadingFileToggle(true);

    const imgFile = event.target.files[0];
    const file = new FormData();
    file.append("type", "rewards");
    file.append("name", this.state.rewards.rewardName.trim().replace(/\s+/g, '_'));
    file.append("file", event.target.files[0]);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const fileResult = fileReader.result;
      let dataAsImg = new Image();
      dataAsImg.src = fileResult;
      this.setState({ previewImage: dataAsImg.src });
    };
    fileReader.readAsDataURL(imgFile);

    // Upload image
    try {
      const { data } = await upload(file);

      if (data.success) {
        messageShow("File uploaded successfully.");

        const { rewards } = this.state;

        setTimeout(() => {
          rewards.image = data.file;
          this.setState({
            rewards,
          });
        }, 1500);
      } else {
        messageShow("There was some error. Please try again.");
      }
    } catch (error) {
      messageShow("There was some error. Please try again.!");
    } finally {
      this.isUploadingFileToggle(false);
    }
  };

  back = () => {
    const { history } = this.props;

    if (history.length > 2) {
      this.props.history.push(routes.listAllTabs.path, { activeTab: 'Rewards' });
    } else {
      history.goBack();
    }
  }

  isLoadingToggle = isLoading => {
    this.setState({
      isLoading
    })
  }

  onUpdate = async (event) => {
    event.preventDefault();

    const { updaterewards, messageShow } = this.props;

    const { rewards } = this.state;

    if (!rewards.startDate) {
      messageShow("Start Date is required.");
      return;
    }

    if (!rewards.expiryDate) {
      messageShow("Expiry Date is required.");
      return;
    }

    if (!rewards.startTime) {
      messageShow("Start Time is required.");
      return;
    }

    if (!rewards.endTime) {
      messageShow("End Time is required.");
      return;
    }

    if (!rewards.onlineDeals.maxDiscountAmount) {
      rewards.onlineDeals.maxDiscountAmount = 0;
    }

    const discountFilled = rewards.onlineDeals.discountPercentage;
    const activeProductsFilled = rewards.activeProducts.length > 0;

    if (!discountFilled && !activeProductsFilled) {
      window.confirm(
        "Please fill in either the discount percentage or active products."
      );
      return;
    }

    if (discountFilled && activeProductsFilled) {
      const proceed = window.confirm(
        "You are giving both product deal and discount percentage. Are you sure you want to continue?"
      );

      if (!proceed) {
        return;
      }
    }

    const { minOrderAmount, maxDiscountAmount } = rewards.onlineDeals;

    if (parseFloat(maxDiscountAmount) > parseFloat(minOrderAmount)) {
      window.alert("Max Discount Amount should be less than Min Order Amount");
      return;
    }

    // if (maxDiscountAmount && (parseFloat(maxDiscountAmount) <= 0 || parseFloat(maxDiscountAmount) >= parseFloat(minOrderAmount))) {
    //   window.alert("Max Discount Amount should be greater than 0 and less than Min Order Amount");
    //   return;
    // }

    this.isLoadingSubmitToggle(true);

    try {
      const { data } = await updaterewards(rewards);

      this.isLoadingSubmitToggle(false);
      messageShow(data.message);

      if (data.success) {
        this.props.history.push(routes.listAllTabs.path, { activeTab: 'Rewards' });
      }
    } catch (error) {
      this.isLoadingSubmitToggle(false);

      messageShow("Some error occurred. Please try again in update ", error);
    }
  };

  onTypeName = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      rewards: {
        ...prevState.rewards,
        [name]: value
      }
    }));
  }

  onType = (event) => {
    const { name, value } = event.target;

    // Allow only digits
    const isNumeric = /^\d*$/.test(value);

    if (!isNumeric) {
      return;
    }

    // if (name === 'onlineDeals.maxDiscountAmount' && value === "0") {
    //   window.alert("Max Discount Amount cannot be zero");
    //   return;
    // }

    const [parent, child] = name.split('.');

    if (child) {
      this.setState(prevState => ({
        rewards: {
          ...prevState.rewards,
          [parent]: {
            ...prevState.rewards[parent],
            [child]: value
          }
        }
      }));
    } else {
      this.setState(prevState => ({
        rewards: {
          ...prevState.rewards,
          [name]: value
        }
      }));
    }
  };
  onChangeOfflineDeals = (event) => {
    this.setState({
      rewards: {
        ...this.state.rewards,
        offlineDeals: event.target.checked,
      },
    });
  };
  toggleShowAllProducts = () => {
    this.setState((prevState) => ({
      showAllProducts: !prevState.showAllProducts,
    }));
  };
  toggleShowAllSubCategories = () => {
    this.setState((prevState) => ({
      showAllSubCategories: !prevState.showAllSubCategories,
    }));
  };


  render() {
    const { classes } = this.props;
    const { rewards, isLoadingSubmit, timeSelection, categories, subCategoryProducts,showAllProducts, selectedSubCategory, selectedSubCategoryName, searchTerm } = this.state;
    const allProducts = selectedSubCategory ? subCategoryProducts[selectedSubCategory] || [] : [];

    const filteredProducts = allProducts.filter((product) =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const allAvailableProducts = Object.values(subCategoryProducts).flat();
    const isDisabled = rewards.isExpired;
    const displayedProducts = showAllProducts
    ? rewards.activeProducts
    : rewards.activeProducts.slice(0, 2); 
    return (
      <SectionPaper>
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={this.back}
          >
            <IconArrowBack />
          </IconButton>

          <Typography variant="h6" color="inherit">
            Update Rewards
          </Typography>
        </Toolbar>
        {rewards.isExpired && (
          <SectionPaper padding style={{ color: 'red', marginLeft:'24px' }}>
          This reward is expired
          </SectionPaper>
        )}
        <Toolbar className={classes.toolbar}>
          <Grid item xs={12} lg={6}>
            <SectionPaper padding>
              <form onSubmit={this.onUpdate}>
                <Grid item xs={12} style={{ marginBottom: 24 }}>
                  <TextField
                    name={"rewardName"}
                    value={nullToEmptyString(rewards.rewardName)}
                    variant="standard"
                    onChange={this.onTypeName}
                    label={"Reward Name"}
                    placeholder={"Enter reward name"}
                    required={true}
                    margin={"dense"}
                    autoComplete={"off"}
                    fullWidth
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6} style={{ marginBottom: 12 }}>
                    <TextField
                      name="earnedPoints"
                      value={rewards.earnedPoints}
                      variant="standard"
                      onChange={this.handleEarnedPointsChange}
                      label={"Earned Points"}
                      placeholder={"Enter Earned Points"}
                      required={true}
                      margin={"dense"}
                      autoComplete={"off"}
                      fullWidth
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ marginBottom: 12 }}>
                    <TextField
                      name="earnedValues"
                      value={rewards.earnedValues}
                      variant="standard"
                      label="Event Earned Values"
                      placeholder="Enter Earned Values"
                      required={true}
                      margin="dense"
                      autoComplete="off"
                      fullWidth
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6} style={{ marginBottom: 24 }}>
                    <TextField
                      name={"onlineDeals.discountPercentage"}
                      value={nullToEmptyString(rewards.onlineDeals && rewards.onlineDeals.discountPercentage)}
                      variant="standard"
                      onChange={this.onType}
                      label={"Discount Percentage"}
                      placeholder={"Enter Discount Percentage"}
                      margin={"dense"}
                      autoComplete={"off"}
                      fullWidth
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ marginBottom: 24 }}>
                    <TextField
                      name={"voucherCode"}
                      value={nullToEmptyString(rewards.voucherCode)}
                      variant="standard"
                      onChange={this.onTypeName}
                      label={"Voucher Code"}
                      placeholder={"Enter Voucher Code"}
                      required={true}
                      margin={"dense"}
                      autoComplete={"off"}
                      fullWidth
                      disabled={isDisabled}
                    />
                  </Grid>

                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6} style={{ marginBottom: 24 }}>
                    <TextField
                      name={"onlineDeals.minOrderAmount"}
                      value={nullToEmptyString(rewards.onlineDeals && rewards.onlineDeals.minOrderAmount)}
                      variant="standard"
                      onChange={this.onType}
                      label={"Min Order Amount"}
                      placeholder={"Enter Min Order Amount"}
                      margin={"dense"}
                      autoComplete={"off"}
                      fullWidth
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ marginBottom: 24 }}>
                    <TextField
                      name={"onlineDeals.maxDiscountAmount"}
                      value={nullToEmptyString(rewards.onlineDeals && rewards.onlineDeals.maxDiscountAmount)}
                      variant="standard"
                      onChange={this.onType}
                      label={"Max Discount Amount"}
                      placeholder={"Enter Max Discount Amount"}
                      margin={"dense"}
                      autoComplete={"off"}
                      fullWidth
                      disabled={isDisabled}
                    />
                  </Grid>

                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6} style={{ marginBottom: 24 }}>
                    <TextField
                      name={"issueVolume"}
                      value={nullToEmptyString(rewards.issueVolume)}
                      variant="standard"
                      onChange={this.onType}
                      label={"Issue Volume"}
                      placeholder={"Enter Issue Volume"}
                      required={true}
                      margin={"dense"}
                      autoComplete={"off"}
                      fullWidth
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ marginBottom: 24 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={rewards.offlineDeals}
                          onChange={this.onChangeOfflineDeals}
                          name="offlineDeals"
                          color="primary"
                          margin="normal"
                          autoComplete="off"
                          disabled={isDisabled}
                        />
                      }
                      label="Offline Deal"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} style={{ marginBottom: 24 }}>
                  <Grid item xs={6} style={{ marginBottom: 24 }}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel>Category</InputLabel>
                      <Select
                        value={this.state.rewards.category || ""}  // Use rewards.category to display selected categories          
                        displayEmpty
                        disabled={isDisabled}
                        renderValue={(selected) => selected || ""}
                        MenuProps={{
                          PaperProps: {
                            style: { maxHeight: 200, width: 200 },
                          },
                        }}
                      >
                        {categories.flatMap((category) => {
                          const categoryItems = [
                            <MenuItem disabled key={category._id}>
                              <ListItemText primary={category.name} style={{ fontWeight: "bold" }} />
                            </MenuItem>,
                          ];

                          const subCategoryItems = category.subCategories.map((subCategory) => (
                            <MenuItem
                              key={subCategory._id}
                              value={subCategory.name}
                              onClick={() => this.handleSubCategorySelect(subCategory._id, subCategory.name)}
                              style={{ paddingLeft: 20 }}
                            >
                              <ListItemText primary={subCategory.name} />
                            </MenuItem>
                          ));

                          return [...categoryItems, ...subCategoryItems];
                        })}
                      </Select>
                    </FormControl>
                    {/* Display selected category as a chip */}
                    {this.state.rewards.category && (
                      <div>
                      <strong>Selected Categories:</strong>
                      <div style={{ marginTop: '8px', display: 'flex', flexWrap: 'wrap' }}>
                        {/* Display only the first 2 or all subcategory chips based on the toggle */}
                        {this.state.rewards.category.split(", ").slice(0, this.state.showAllSubCategories ? 20 : 2).map((categoryName, index) => (
                          <Chip
                            key={index}
                            label={categoryName}
                            style={{
                              marginRight: '8px',
                              marginBottom: '4px',
                              position: 'relative',
                              paddingRight: '11px',
                              height: '24px',
                              fontSize: '12px',
                            }}
                          />
                        ))}
                        
                        {/* Toggle Show More / Show Less if there are more than 2 subcategories */}
                        {this.state.rewards.category.split(", ").length > 2 && !this.state.showAllSubCategories && (
                          <Chip
                            label={`+${this.state.rewards.category.split(", ").length - 2}`}
                            clickable
                            onClick={this.toggleShowAllSubCategories}
                            style={{
                              marginTop: '8px',
                              marginBottom: '4px',
                              variant: 'outlined',
                              color: 'white',
                              fontSize: '11px',
                              padding: '6 6px',
                              backgroundColor: '#1976d2',
                              cursor: 'pointer',
                              height: '24px',
                              lineHeight: '24px',
                            }}
                          />
                        )}
                        
                        {/* Show Less button */}
                        {this.state.showAllSubCategories && this.state.rewards.category.split(", ").length > 2 && (
                          <Chip
                            label="Show Less"
                            clickable
                            onClick={this.toggleShowAllSubCategories}
                            style={{
                              marginTop: '8px',
                              marginBottom: '4px',
                              fontSize: '12px',
                              padding: '0 4px',
                              color: 'white',
                              backgroundColor: '#1976d2',
                              variant: 'outlined',
                              cursor: 'pointer',
                              height: '24px',
                              lineHeight: '24px',
                            }}
                          />
                        )}
                      </div>
                    </div>
                    )}
                  </Grid>
                  <Grid item xs={6} style={{ marginBottom: 24 }}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel>Active Products</InputLabel>
                      <Select
                        multiple
                        value={rewards.activeProducts}
                        onChange={(e) => this.handleProductSelect(e.target.value)}
                        renderValue={(selectedIds) => {
                          const allAvailableProducts = Object.values(subCategoryProducts).flat();

                          const selectedNames = selectedIds?.map(id => {
                            const product = allAvailableProducts.find(p => p._id === id);
                            if (product) {
                              return product.name;
                            } else {
                              console.warn(`Product with ID ${id} not found`);
                              return '';
                            }
                          }) || [];

                          return selectedNames.join(", ");
                        }}

                        disabled={!selectedSubCategory || isDisabled}

                        MenuProps={{
                          PaperProps: {
                            style: { maxHeight: 200, width: 200 },
                          },
                        }}
                        onClose={this.handleActiveProductsDropdownClose}>
                        <div style={{ padding: '15px' }}>
                          <TextField
                            placeholder="Search products"
                            variant="outlined"
                            value={searchTerm}
                            onChange={this.handleSearchChange}
                            InputProps={{
                              style: { height: '40px' },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                            style={{ width: '100%' }}
                          />
                        </div>

                        {filteredProducts.length > 0 ? (
                          filteredProducts.map((product) => (
                            <MenuItem
                              key={product._id}
                              value={product._id}
                            >
                              <ListItemText primary={product.name} />
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>
                            <ListItemText primary={searchTerm ? "No products found" : "No products available"} />
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    {rewards.activeProducts.length > 0 && (
                      <div style={{ marginTop: '8px', color: 'gray', fontSize: '14px' }}>
                        <strong>Selected Products:</strong>
                        <div style={{ marginTop: '8px', display: 'flex', flexWrap: 'wrap' }}>
                          {displayedProducts.map((productId) => {
                            const product = allAvailableProducts.find((p) => p._id === productId);
                            return product ? (
                              <Chip
                                key={product._id}
                                label={product.name}
                                disabled={isDisabled}
                                onDelete={() => this.handleDeleteProduct(product._id)}
                                deleteIcon={
                                <CloseIcon style={{
                                  fontSize: '12px',  
                                  color: 'white',     
                                  backgroundColor: 'red', 
                                  borderRadius: '50%',    
                                  padding: '2px',         
                                  position: 'absolute',  
                                  top: '-4px',
                                  right: '-4px',
                                }} 
                                />
                              }
                                style={{
                                  marginRight: '8px',
                                  marginBottom: '4px',
                                  position: 'relative',  
                                  paddingRight: '11px',  
                                  height: '24px',        
                                  fontSize: '12px',
                                }}
                              />
                            ) : null;
                          })}
                          {rewards.activeProducts.length > 2 && !showAllProducts && (
                            <Chip
                              label={`+${rewards.activeProducts.length - 2}`}
                              clickable
                              onClick={this.toggleShowAllProducts}
                              style={{
                                marginTop: '8px',
                                marginBottom: '4px',
                                variant:'outlined',
                                color: 'white',
                                fontSize: '11px', 
                                padding: '6 6px', 
                                backgroundColor: '#1976d2', 
                                cursor: 'pointer',
                                height: '24px', 
                                lineHeight: '24px',
                              }}
                            />
                          )}
                          {showAllProducts && rewards.activeProducts.length > 2 && (
                            <Chip
                              label="Show Less"
                              clickable
                              onClick={this.toggleShowAllProducts}
                              style={{
                                marginTop: '8px',
                                marginBottom: '4px',
                                fontSize: '12px',
                                padding: '0 4px',
                                color: 'white',
                                backgroundColor: '#1976d2',
                                variant:'outlined',
                                cursor: 'pointer',
                                height: '24px',
                                lineHeight: '24px',
                              }}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </Grid>

                </Grid>
                <Grid item xs={12} container spacing={3} style={{ marginBottom: 24 }}>
                  <Grid item xs={6}>
                    <DatePicker
                      required
                      label="Start Date"
                      value={rewards.startDate ? dayjs(rewards.startDate) : null}
                      onChange={this.handleDateChange('startDate')}
                      disabled={isDisabled}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <DatePicker
                      required
                      label="Expiry Date"
                      value={rewards.expiryDate ? dayjs(rewards.expiryDate) : null}
                      onChange={this.handleDateChange('expiryDate')}
                      disabled={isDisabled}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} >
                    <InputLabel variant="standard">Time Option</InputLabel>
                    <FormControl component="fieldset">
                      <RadioGroup
                        value={timeSelection}
                        onChange={this.handleTimeSelectionChange}
                        row
                        disabled={isDisabled}
                      >
                        <FormControlLabel
                          control={<Radio value="24-hour" />}
                          label="24-Hour Timing"
                          disabled={isDisabled}
                        />
                        <FormControlLabel
                          control={<Radio value="custom" />}
                          label="Custom Timing"
                          disabled={isDisabled}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={3} style={{ marginBottom: 24, marginTop: 24 }}>
                  <Grid item xs={6}>
                    <TimePicker
                      required={timeSelection === 'custom'}
                      value={rewards.startTime ? dayjs(rewards.startTime, 'HH:mm') : null}
                      label="Start Time"
                      inputVariant="standard"
                      disabled={isDisabled}
                      onChange={(time) => this.handleTimeChange(time, 'startTime')}
                      InputProps={{
                        readOnly: timeSelection === '24-hour',
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TimePicker
                      required={timeSelection === 'custom'}
                      value={rewards.endTime ? dayjs(rewards.endTime, 'HH:mm') : null}
                      label="End Time"
                      inputVariant="standard"
                      disabled={isDisabled}
                      onChange={(time) => this.handleTimeChange(time, 'endTime')}
                      InputProps={{
                        readOnly: timeSelection === '24-hour',
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} container spacing={3} style={{ marginBottom: 24, marginTop: 24 }}>
                  <Grid
                    container
                    spacing={8}
                    className={classes.buttonUpload}
                    alignItems="center"
                  >
                    <Grid item md={6}>
                      {!this.state.previewImage ? (
                        <ImageComp
                          src={getImageSource(`${rewards.image}`)}
                          defaultSrc={getImageSource()}
                          size={"100%"}
                        />
                      ) : (
                        <img
                          height="auto"
                          width="100%"
                          src={this.state.previewImage}
                          alt="preview_rewards"
                        ></img>
                      )}
                    </Grid>
                    {!rewards.isExpired && (
                    <Grid item md={6}>
                      <input
                        accept={"image/png,image/jpeg"}
                        style={{ display: "none" }}
                        id={"contained-button-file"}
                        type={"file"}
                        onChange={this.onUpload}
                      />
                      <label htmlFor={"contained-button-file"}>
                        <Button
                          variant={"outlined"}
                          component={"span"}
                          type={"file"}
                          fullWidth
                        >
                          <FileUpload
                            fontSize="16"
                            style={{ marginInlineEnd: 16 }}
                          />
                          Upload Image
                        </Button>
                      </label>
                    </Grid>
                    )}
                  </Grid>
                </Grid>
                {!rewards.isExpired && (
                <Grid item xs={12} className={classes.buttonsContainer}>
                  <Button
                    type={"button"}
                    aria-label={"Close"}
                    variant="outlined"
                    color="secondary"
                    onClick={this.back}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ marginInlineStart: 12 }}
                    type={"submit"}
                    aria-label={"Save"}
                    color={"primary"}
                    variant="contained"
                    disabled={isLoadingSubmit}
                  >
                    update
                  </Button>
                </Grid>)}
              </form>
            </SectionPaper>
          </Grid>
        </Toolbar>
      </SectionPaper>
    );
  }
}
updateRewards.propTypes = {
  updaterewards: PropTypes.func.isRequired,
  getrewards: PropTypes.func.isRequired,
  getPoints: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  listCategories: PropTypes.func.isRequired,
  productlistByCategory: PropTypes.func.isRequired
};
function listState(state) {
  return {
    categoriesParent: state.categoriesParent,
  };
}

export default connect(listState, {
  getrewards,
  getPoints,
  updaterewards,
  upload,
  messageShow,
  listCategories,
  productlistByCategory
})(withStyles(styles)(updateRewards))


