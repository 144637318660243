import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField} from '@mui/material';
import { getPricingData } from '../api/action/query';
import { updateProductPrice } from '../api/action/mutation';
import { messageShow } from '../../common/api/actions';
import AnalyticsChart from './AnalyticsChart';
import { calculatePricingData } from '../utility/priceComparison';
import Loading from '../../common/Loading';

const Pricing = () => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const [pricingData, setPricingData] = useState({ chartOptions: {}, chartSeries: [], tableData: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [newStorePrice, setNewStorePrice] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (products && storeData) {
      const calculatedData = calculatePricingData(products, storeData);
      setPricingData(calculatedData);
    }
  }, [products, storeData]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await getPricingData();
      setProducts(response?.pricingData || []);
      setStoreData(response?.productsData || []);
    } catch (error) {
      console.error('Error fetching data:', error);
      dispatch(messageShow('Failed to fetch data'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleAlter = (product) => {
    setSelectedProduct(product);
    setNewStorePrice(product.price);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedProduct(null);
    setNewStorePrice('');
  };

  const handleSaveNewPrice = async () => {
    try {
      const response = await updateProductPrice(selectedProduct.productName, parseFloat(newStorePrice));
      if (response.error) {
        throw new Error(response.error);
      }
      const updatedProducts = products.map(product => 
        product.productName === selectedProduct.productName
          ? {...product, price: parseFloat(newStorePrice)}
          : product
      );
      
      const updatedStoreData = storeData.map(prod => 
        prod.productName === selectedProduct.productName
          ? {...prod, price: parseFloat(newStorePrice)}
          : prod
      );
      
      setProducts(updatedProducts);
      setStoreData(updatedStoreData);
      const calculatedData = calculatePricingData(updatedProducts, updatedStoreData);
      setPricingData(calculatedData);
      handleCloseDialog();
      dispatch(messageShow('Price updated successfully'));
      await fetchData();
    } catch (error) {
      console.error('Error updating price:', error);
      dispatch(messageShow(error.message));
    }
  };

  if(isLoading){
    return <Loading />
  }
  if (!products.length || !storeData.length)  return <>No data available</>

  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
      <div style={{ flex: 1, marginRight: "10px" }}>
        <AnalyticsChart 
          name="pricing" 
          data={products}
          storeData={storeData}
          onAlter={handleAlter}
        />
      </div>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Alter Store Price for {selectedProduct?.productName}</DialogTitle>
        <DialogContent>
        <p>Current Store Price: <strong>{selectedProduct?.price}</strong></p>
          <TextField
            autoFocus
            margin="dense"
            label="New Store Price"
            type="number"
            fullWidth
            value={newStorePrice}
            onChange={(e) => setNewStorePrice(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveNewPrice} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Pricing;