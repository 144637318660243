// Imports
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
// UI Imports
import Typography from "@material-ui/core/Typography";
import IconBarChart from "@material-ui/icons/BarChart";
import IconCategoryOutlined from "@material-ui/icons/CategoryOutlined";
import IconDashboardOutlined from "@material-ui/icons/DashboardOutlined";
import IconFilterNoneOutlined from "@material-ui/icons/FilterNoneOutlined";
import IconLocationCityOutlined from "@material-ui/icons/LocationCityOutlined";
import IconForumOutlined from "@material-ui/icons/ForumOutlined";
import GiftIcon from "@material-ui/icons/CardGiftcard";
import SendIcon from "@mui/icons-material/Send";
import IconPeople from "@material-ui/icons/People";
import QuizIcon from "@mui/icons-material/Quiz";
import IconPeopleOutline from "@material-ui/icons/PeopleOutline";
import IconPhotoLibraryOutlined from "@material-ui/icons/PhotoLibraryOutlined";
import TrendingUpRoundedIcon from "@mui/icons-material/TrendingUpRounded";
import IconShoppingCartOutlined from "@material-ui/icons/ShoppingCartOutlined";
import { messageShow } from "../../common/api/actions";
import { detail } from "../../setting/api/actions/query";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
// App Imports
import params from "../../../setup/config/params";
import routes from "../../../setup/routes";
import styles from "./styles";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Component
class Sidebar extends PureComponent {
  constructor(props) {
    super(props);
    this.settings = {
      isRewardsSubscribed: false,
      isMessagingSubscribed:false,
      isAnalyticsSubscribed:false
    };

    this.state = {
      settings: this.settings,
      isHovered: false,  
      isSidebarOpen: false,    
    };
  }

  isActiveRoute = (routePath) => {
    const { location } = this.props;

    return location.pathname.indexOf(routePath) !== -1 ? "primary" : "inherit";
  };

  componentDidMount() {
    this.refresh();
    window.addEventListener('resize', this.handleResize);

  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }


  refresh = async () => {
    const { detail, messageShow } = this.props;
    try {
      const {
        match: {
          params: { vendorId },
        },
      } = this.props;
      this.setState({
        settings: { vendorId },
      });
      const { data } = await detail({ vendorId });
      if (data.success && data.data) {
        const { isRewardsSubscribed,isMessagingSubscribed,isAnalyticsSubscribed } = data.data;
        this.setState({
          settings: {
            ...this.state.settings,
            isRewardsSubscribed: isRewardsSubscribed,
            isMessagingSubscribed: isMessagingSubscribed,
            isAnalyticsSubscribed: isAnalyticsSubscribed
          },
        });
      }
    } catch (error) {
      messageShow("Some error occurred. Please try again.", error);
    }
  };

  toggleSidebar = () => {
    this.setState(prevState => ({ isSidebarOpen: !prevState.isSidebarOpen }));
  };

  handleResize = () => {
    if (window.innerWidth > 768) { 
      this.setState({ isSidebarOpen: false });
    } else {
      this.setState({ isSidebarOpen: false });
    }
  };
  render() {
    const {
      classes,
      auth: { user },
    } = this.props;
    const { settings, isHovered, isSidebarOpen } = this.state;
    
    const sidebarStyle = {
      width: isSidebarOpen ? '200px' : '60px',
      transition: 'width 0.3s ease',
    };
    const iconStyle = {
      color: isHovered ? 'blue' : 'inherit',
      transition: 'color 0.3s',
    };
    return (
      <div
        className={classes.sidenavWrapper}
        style={sidebarStyle}
      >     
        <div className={`${classes.sidenav} ${isSidebarOpen ? classes.expanded : ''}`}>
    
          <ListItem
            onMouseEnter={() => this.setState({ isHovered: true })}
            onMouseLeave={() => this.setState({ isHovered: false })}
          >
            <ListItemIcon onClick={this.toggleSidebar} style={iconStyle}>
             {isSidebarOpen ? <ArrowBackIcon style={iconStyle}/> : <MenuIcon style={iconStyle}/>}
            </ListItemIcon>
            <ListItemText primary={isSidebarOpen ? "Menu": "" }/>
           </ListItem>                   
          <List component="nav">
            {/* Dashboard */}
            <ListItem to={routes.userDashboard.path} component={Link} button>
             <Tooltip title={isSidebarOpen ? "" : "Dashboard"} arrow>
              <ListItemIcon>
                <IconDashboardOutlined
                  color={this.isActiveRoute(routes.userDashboard.path)}
                />
              </ListItemIcon>
             </Tooltip>
              <ListItemText primary={isSidebarOpen ? "Dashboard": "" }/>           
              </ListItem>

            {/* Orders */}
            <ListItem to={routes.orderList.path(1)} component={Link} button>
             <Tooltip title={isSidebarOpen ? "" : "Orders"} arrow>
              <ListItemIcon>
                <IconShoppingCartOutlined
                  color={this.isActiveRoute(routes.orderList.activePath)}
                />
              </ListItemIcon>
             </Tooltip>
              <ListItemText primary={isSidebarOpen ? "Orders" : "" } />
            </ListItem>

            {/* Banners */}
            <ListItem to={routes.bannerList.path} component={Link} button>
             <Tooltip title={isSidebarOpen ? "" : "Banners"} arrow>
              <ListItemIcon>
                <IconPhotoLibraryOutlined
                  color={this.isActiveRoute(routes.bannerList.path)}
                />
              </ListItemIcon>
             </Tooltip>
              <ListItemText primary={isSidebarOpen? "Banners" : ""}/>
            </ListItem>

            {/* Settings */}
            {/* <ListItem
                  to={routes.settings.path}
                  component={Link}
                  button
                >
                  <ListItemIcon>
                    <IconSettingsOutlined color={this.isActiveRoute(routes.settings.path)} />
                  </ListItemIcon>
                  <ListItemText primary="Settings" />
                </ListItem> */}
          </List>

          <Divider />

          <List component="nav">
            {/* Categories */}
            {user.role === params.user.roles.admin.key && (
              <ListItem to={routes.categoryList.path} component={Link} button>
               <Tooltip title={isSidebarOpen ? "" : "Categories"} arrow>
                <ListItemIcon>
                  <IconCategoryOutlined
                    color={this.isActiveRoute(routes.categoryList.path)}
                  />
                </ListItemIcon>
               </Tooltip>
                <ListItemText primary={isSidebarOpen ? "Categories" : ""}/>
              </ListItem>
            )}

            {/* Products */}
            <ListItem to={routes.productList.path(1)} component={Link} button>
             <Tooltip title={isSidebarOpen ? "" : "Products"} arrow>
              <ListItemIcon>
                <IconFilterNoneOutlined
                  color={this.isActiveRoute(routes.productList.activePath)}
                />
              </ListItemIcon>
             </Tooltip>
              <ListItemText primary={isSidebarOpen ? "Products": ""}/>
            </ListItem>
          </List>

          <Divider />

          {user.role === params.user.roles.admin.key && (
            <>
              <List component="nav">
                {/* Customers */}
                <ListItem
                  to={routes.customerList.path(1)}
                  component={Link}
                  button
                >
                 <Tooltip title={isSidebarOpen ? "" : "Customers"} arrow>
                  <ListItemIcon>
                    <IconPeopleOutline
                      color={this.isActiveRoute(routes.customerList.activePath)}
                    />
                  </ListItemIcon>
                 </Tooltip>
                  <ListItemText primary={isSidebarOpen ? "Customers" : ""}/>
                </ListItem>

                {/* Delivery Person */}

                {/* <ListItem
                  to={routes.deliveryBoyList.path}
                  component={Link}
                  button
                >
                  <ListItemIcon>
                    <IconDirectionsBikeOutlined color={this.isActiveRoute(routes.deliveryBoyList.path)} />
                  </ListItemIcon>
                  <ListItemText primary="Delivery Boy" />
                </ListItem> */}

                {/* Areas */}
                <ListItem to={routes.pincodeList.path} component={Link} button>
                 <Tooltip title={isSidebarOpen ? "" : "Areas"} arrow>
                  <ListItemIcon>
                    <IconLocationCityOutlined
                      color={this.isActiveRoute(routes.pincodeList.path)}
                    />
                  </ListItemIcon>
                 </Tooltip>
                  <ListItemText primary={isSidebarOpen ? "Areas": ""}/>
                </ListItem>
              </List>
              <Divider />

              <List component="nav">
                {/* Broadcast Message */}
                {/*<ListItem
                  to={routes.broadcastList.path}
                  component={Link}
                  button
                >
                  <ListItemIcon>
                    <IconForumOutlined color={this.isActiveRoute(routes.broadcastList.path)} />
                  </ListItemIcon>
                  <ListItemText primary="Broadcast Msg" />
                </ListItem>*/}
              </List>

              {/* Reports */}
              {user.role === params.user.roles.admin.key && (
                <ListItem to={routes.userReports.path} component={Link} button>
                 <Tooltip title={isSidebarOpen ? "" : "Reports"} arrow>
                  <ListItemIcon>
                    <IconBarChart
                      color={this.isActiveRoute(routes.userReports.path)}
                    />
                  </ListItemIcon>
                 </Tooltip>
                  <ListItemText primary={isSidebarOpen ? "Reports": ""}/>
                </ListItem>
              )}
              {/* {Messaging} */}
              <Tooltip
                title={
                  settings.isMessagingSubscribed
                    ? ""
                    : "Subscribe to access this feature"
                }
              >
                <ListItem
                      color={this.isActiveRoute(routes.messaging.path)}
                  to={
                    settings.isMessagingSubscribed
                      ? routes.messaging.path
                      : null
                  }
                  component={settings.isMessagingSubscribed ? Link : "div"}
                  button={settings.isMessagingSubscribed}
                  disabled={!settings.isMessagingSubscribed}
                  style={{
                    cursor: settings.isMessagingSubscribed
                      ? "pointer"
                      : "",
                  }}
                >
                 <Tooltip title={isSidebarOpen ? "" : "Messaging"} arrow>
                  <ListItemIcon>
                    <SendIcon
                      color={this.isActiveRoute(routes.messaging.path)}
                    />
                  </ListItemIcon>
                 </Tooltip>
                  <ListItemText primary={isSidebarOpen ? "Messaging": ""}/>
                </ListItem>
              </Tooltip>


              {/* {Analytics} */}
                <Tooltip
                title={
                  settings.isAnalyticsSubscribed
                    ? ""
                    : "Subscribe to access this feature"
                }
              >
                <ListItem
                  to={
                    settings.isAnalyticsSubscribed
                      ? routes.analytics.path
                      : null
                  }
                  component={settings.isAnalyticsSubscribed ? Link : "div"}
                  button={settings.isAnalyticsSubscribed}
                  disabled={!settings.isAnalyticsSubscribed}
                  style={{
                    cursor: settings.isAnalyticsSubscribed
                      ? "pointer"
                      : "",
                  }}
                >
                  <Tooltip title={isSidebarOpen ? "" : "Analytics"} arrow>
                    <ListItemIcon>
                      <TrendingUpRoundedIcon
                        color={this.isActiveRoute(routes.analytics.path)}
                      />
                    </ListItemIcon>
                  </Tooltip>
                    <ListItemText primary={isSidebarOpen ? "Analytics": ""}/>
                  </ListItem>
              </Tooltip>

              {/* Vendors */}
              <ListItem to={routes.vendorList.path(1)} component={Link} button>
               <Tooltip title={isSidebarOpen ? "" : "Vendors"} arrow>
                <ListItemIcon>
                  <IconPeople
                    color={this.isActiveRoute(routes.vendorList.activePath)}
                  />
                </ListItemIcon>
               </Tooltip>
                <ListItemText primary={isSidebarOpen ? "Vendors": ""}/>
              </ListItem>

              {/* Faq */}

              <ListItem to={routes.faq.path} component={Link} button>
               <Tooltip title={isSidebarOpen ? "" : "Faq"} arrow>
                <ListItemIcon>
                  <QuizIcon color={this.isActiveRoute(routes.faq.path)} />
                </ListItemIcon>
               </Tooltip>
                <ListItemText primary={isSidebarOpen ? "Faq": ""} />
              </ListItem>

              {/* Feedback */}
              <ListItem to={routes.ratingFeedback.path(1)} component={Link} button>
               <Tooltip title={isSidebarOpen ? "" : "Feedback"} arrow>
                <ListItemIcon>
                  <IconForumOutlined
                    color={this.isActiveRoute(routes.ratingFeedback.path)}
                  />
                </ListItemIcon>
               </Tooltip>
                <ListItemText primary={isSidebarOpen ? "Feedback": ""}/>
              </ListItem>
              {/* Rewards */}
              <Tooltip
                title={
                  settings.isRewardsSubscribed
                    ? ""
                    : "Subscribe to access this feature"
                }
              >
                <ListItem
                  to={
                    settings.isRewardsSubscribed
                      ? routes.listAllTabs.path
                      : null
                  }
                  component={settings.isRewardsSubscribed ? Link : "div"}
                  button={settings.isRewardsSubscribed}
                  disabled={!settings.isRewardsSubscribed}
                  style={{
                    cursor: settings.isRewardsSubscribed
                      ? "pointer"
                      : "default",
                  }}
                >
                 <Tooltip title={isSidebarOpen ? "" : "Rewards"} arrow>
                  <ListItemIcon>
                    <GiftIcon
                      color={this.isActiveRoute(routes.listAllTabs.path)}
                    />
                  </ListItemIcon>
                 </Tooltip>
                  <ListItemText primary={isSidebarOpen ? "Rewards": ""}/>
                </ListItem>
              </Tooltip>
              <Divider />
            </>
          )}

          <ListItem>
            {/* Copyright */}
            <Typography variant="caption" color="textSecondary">
              &copy; {params.site.year}{" "}
              <a
                href={params.site.url.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                {params.site.name}
              </a>
            </Typography>
          </ListItem>
        </div>
      </div>
    );
  }
}

// Component Properties
Sidebar.propTypes = {
  auth: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  messageShow: PropTypes.func.isRequired,
  detail: PropTypes.func.isRequired,
};

// Component State
function sidebarState(state) {
  return {
    auth: state.auth,
  };
}

export default withRouter(
  connect(sidebarState, { detail, messageShow })(withStyles(styles)(Sidebar))
);
