// Imports
import { withStyles } from '@material-ui/core'
import Table from "@material-ui/core/Table";
import { Send, FileCopy } from '@mui/icons-material';
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { messageShow } from '../../common/api/actions'
import SectionPaper from '../../common/SectionPaper'
import PropTypes from "prop-types";
import Loading from "../../common/Loading";
import { createRewards } from '../apiRewards/actions/mutation'
import { IconButton, Tooltip } from '@mui/material'
import { Popover, Pagination } from '@mui/material';
import {
  nullToEmptyString,
} from "../../../setup/helpers";
import { settingDetail } from '../../setting/api/actions/query';
import styles from './styles'
import dateFormat from 'date-fns/format'
import dateParseISO from 'date-fns/parseISO'
import { listrewards } from "../apiRewards/actions/query";
import routes from '../api/routes';
import { getImageSource } from '../apiRewards/routes'
import Image from '../../common/Image'
import Checkbox from "@mui/material/Checkbox";
import { Dialog, DialogContent } from '@mui/material';
import CreateEdit from '../../marketing/CreateEdit';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

// Component
class Rewards extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      count: 0,
      currentPage: 1,
      currencySymbol: '', 
      rewardsPerPage: 10,
      sortDirection: null,
      sortedRewards: props.rewards,
      popoverAnchor: null,
      selectedProductDetails: [],
    }
  }
  
  async componentDidMount() {
    const { settingDetail } = this.props;
    try {
      const response = await settingDetail(); 
      if (response && response.data) {
        const { data } = response;
        const currencySymbol = data.data.currencyData.symbol; 
        this.setState({
          currencySymbol,
          isLoading: false,
        });
      } else {
        messageShow('Error fetching settings');
        this.setState({ isLoading: false });
      }
    } catch (error) {
      console.error('Error fetching setting details:', error);
      messageShow('Error fetching settings');
      this.setState({ isLoading: false });
    }
  }

  handlePageChange = (event, pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  handleSortByExpiryDate = () => {
    const { sortDirection } = this.state;
    const { rewards } = this.props;
    let newDirection = 'asc';

    if (sortDirection === 'asc') {
      newDirection = 'desc';
    }

    const sortedRewards = [...rewards].sort((a, b) => {
      const dateA = new Date(a.expiryDate);
      const dateB = new Date(b.expiryDate);

      if (newDirection === 'asc') {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });

    this.setState({
      sortedRewards,
      sortDirection: newDirection,
      currentPage: 1,
    });
  };

  renderSortArrow = () => {
    const { sortDirection } = this.state;
    if (!sortDirection) return null;

    const arrowStyle = {
      color: '#007BFF'
    };

    return (
      <span style={arrowStyle}>
        {sortDirection === 'asc' ? '▲' : '▼'}
      </span>
    );
  };

  handleClone = async (reward) => {

    const { createRewards, messageShow, rewards, refresh } = this.props;

    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 30);
    const formattedExpiryDate = expiryDate.toISOString().split('T')[0];

    const clonedReward = {
      ...reward,
      rewardName: `${reward.rewardName} (Clone)`,
      startDate: new Date().toISOString().split('T')[0],
      expiryDate: formattedExpiryDate,
    };

    try {
      const { data } = await createRewards(clonedReward);
      if (data.success) {
        this.setState((prevState) => ({
          rewards: [...(rewards || []), data.data],
        }));
        refresh();
        messageShow('Reward cloned successfully!');
      } else {
        messageShow(data.message);
      }
    } catch (error) {
      console.error('Error cloning Reward:', error);
      messageShow('Error cloning Reward. Please try again.');
    }
  };

  handleSelectReward = (rewardId) => {
    this.props.onSelectReward(rewardId);
  };

  handleSelectAllRewards = () => {
    this.props.onSelectAllRewards(this.state.Rewards);
  };

  isRewardExpired = (reward) => {
    return reward.isExpired;
  };

  handlePopoverOpen = (event, productDetails) => {
    this.setState({
      popoverAnchor: event.currentTarget,
      selectedProductDetails: productDetails,
    });
  };

  handlePopoverClose = () => {
    this.setState({ popoverAnchor: null, selectedProductDetails: [] });
  };

  render() {
    const { isLoading, currentPage, rewardsPerPage, sortedRewards, popoverAnchor, selectedProductDetails, currencySymbol } = this.state;
    const { rewards, selectedRewards, messageDialogOpen, openMessagingDialog, closeMessagingDialog, selectedRewardId } = this.props;
    const columnCount = 14; // Number of columns in the table
    const indexOfLastReward = currentPage * rewardsPerPage;
    const indexOfFirstReward = indexOfLastReward - rewardsPerPage;
    const currentRewards = sortedRewards.slice(indexOfFirstReward, indexOfLastReward);

    const totalPages = Math.ceil(sortedRewards.length / rewardsPerPage);
    const enabledRewards = rewards.filter(reward => !this.isRewardExpired(reward));
    const isAllSelected = enabledRewards.length > 0 &&
      enabledRewards.length === Object.keys(selectedRewards).filter(id => !this.isRewardExpired(rewards.find(reward => reward._id === id))).length;
    const open = Boolean(popoverAnchor);
    return (
      <div>
        <SectionPaper>
          <Table padding={"normal"}>
            <TableHead>
              <TableRow>
                <TableCell width={90}>
                  <Checkbox
                    checked={isAllSelected}
                    onChange={this.handleSelectAllRewards}
                  />
                </TableCell>
                <TableCell width={90}>Image</TableCell>
                <TableCell width={130}>Earned Points</TableCell>
                {/* <TableCell width={100}>Earned values</TableCell> */}
                <TableCell width={130}>Rewards name</TableCell>
                <TableCell width={130}>Offline Deals</TableCell>
                <TableCell width={130}>Discount</TableCell>
                {/* <TableCell width={140}>Product Deals</TableCell> */}
                <TableCell width={130}>Min order</TableCell>
                <TableCell width={130}>Voucher Code</TableCell>
                {/* <TableCell width={140}>Issue Volume</TableCell>
                <TableCell width={140}>Start date </TableCell> */}
                <TableCell width={130} onClick={this.handleSortByExpiryDate} style={{ cursor: 'pointer' }}>Expiry date  {this.renderSortArrow()}</TableCell>
                <TableCell width={160} align="center">
                  Actions </TableCell>
              </TableRow>
            </TableHead>

            {isLoading ? (
              <Loading />
            ) : sortedRewards.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={columnCount} style={{
                    textAlign: 'center',
                    padding: '25px',
                    fontSize: '20px',
                    variant: 'h6',
                  }}>
                    No Rewards Found
                  </TableCell>
                  {/* <EmptyMessage message={" No products found"} /> */}
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>

                {currentRewards.map(({ _id, image, offlineDeals, onlineDeals, category, activeProducts, rewardName, earnedPoints, earnedValues, voucherCode, issueVolume, startDate, expiryDate, startTime, endTime, isExpired, productDetails }) => {
                  const isDisabled = this.isRewardExpired({ isExpired });
                  return (
                    <TableRow key={_id} hover>
                      <TableCell>
                        <Checkbox
                          checked={!!selectedRewards[_id]}
                          onChange={(e) => {
                            e.stopPropagation();
                            if (!isDisabled) this.handleSelectReward(_id);
                          }}
                          disabled={isDisabled}
                        />
                      </TableCell>
                      <TableCell>
                        <Image
                          src={getImageSource(`${image}`)}
                          defaultSrc={getImageSource()}
                        />
                      </TableCell>
                      <TableCell>{earnedPoints}</TableCell>
                      {/* <TableCell>{earnedValues}</TableCell> */}
                      {/* <TableCell width={100}>{rewardName}</TableCell> */}
                      <TableCell
                        style={{
                          width: 100,
                          maxWidth: 100, 
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        <Tooltip title={rewardName}>
                          <span>{rewardName}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={offlineDeals}
                          // disabled
                          color="primary"
                        />
                      </TableCell>
                      <TableCell>{`${onlineDeals.discountPercentage}%`}</TableCell>
                      {/* <TableCell>
                      {productDetails && productDetails.length > 0 ? (
                        <>
                          {productDetails.slice(0, 2).map(({ name }) => name).join(', ')}
                          {productDetails.length > 2 && (
                            <IconButton
                              size="small"
                              onClick={(e) => this.handlePopoverOpen(e, productDetails)}
                            >
                              <MoreHorizIcon />
                            </IconButton>
                          )}
                        </>
                      ) : (
                        'No Active Products'
                      )}
                    </TableCell> */}
                      {/* <TableCell>{onlineDeals.minOrderAmount ? `$${onlineDeals.minOrderAmount}` : '-'}</TableCell> */}
                      <TableCell>{onlineDeals.minOrderAmount ? `${currencySymbol}${onlineDeals.minOrderAmount}` : '-'}</TableCell>
                      <TableCell>{voucherCode}</TableCell>
                      {/* <TableCell>{issueVolume}</TableCell>
                    <TableCell>{dateFormat(dateParseISO(startDate), 'yyyy-MM-dd')}</TableCell> */}
                      <TableCell>{dateFormat(dateParseISO(expiryDate), 'yyyy-MM-dd')}</TableCell>
                      <TableCell align="center">
                        <Tooltip title="Edit" arrow>
                          <Button color="primary" component={Link} to={routes.editRewards.path(_id)}><EditIcon /></Button>
                        </Tooltip>
                        <Tooltip title="Create notification">
                          <IconButton color="primary" onClick={(e) => { e.stopPropagation(); this.props.openMessagingDialog(_id); }} disabled={isDisabled}>
                            <Send />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Clone">
                          <span>
                            <IconButton
                              aria-label="clone"
                              size="medium"
                              onClick={(e) => { e.stopPropagation(); this.handleClone({ _id, image, offlineDeals, onlineDeals, category, activeProducts, rewardName, earnedPoints, earnedValues, voucherCode, issueVolume, startDate, expiryDate, startTime, endTime }); }}
                            >
                              <FileCopy color="primary" />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}

          </Table>
          <Popover
            open={open}
            anchorEl={popoverAnchor}
            onClose={this.handlePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <div style={{ padding: '15px', minWidth: '250px', maxHeight: '230px', overflowY: 'auto' }}>
              {selectedProductDetails.map(({ name, image }, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <Image
                    src={getImageSource(`${image}`)}
                    defaultSrc={getImageSource()}
                  />
                  <span style={{ marginLeft: "15px" }}>{name}</span>
                </div>
              ))}
            </div>
          </Popover>
          {sortedRewards.length > rewardsPerPage && (
            <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={this.handlePageChange}
                color="primary"
                siblingCount={1}
                boundaryCount={1}
              />
            </div>
          )}
        </SectionPaper>
        <Dialog open={messageDialogOpen} onClose={closeMessagingDialog} fullWidth>
          <DialogContent>
            <CreateEdit
              onClose={closeMessagingDialog}
              rewardData={{ ...sortedRewards.find(reward => reward._id === selectedRewardId), type: "reward" }}
            />
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}
Rewards.propTypes = {
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  createRewards: PropTypes.func.isRequired,
  settingDetail: PropTypes.func.isRequired,
};
function listState(state) {
  return {
    rewardsParent: state.rewardsParent,
  };
}

export default connect(listState, {
  listrewards,
  messageShow,
  createRewards,
  settingDetail
})(withStyles(styles)(Rewards))