// Imports
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Divider from '@mui/material/Divider'; // Import Divider

// UI Imports
import {Typography, Toolbar, Button, Grid, Card, CardContent} from "@mui/material"
import { withStyles } from "@material-ui/core/styles";
import styles from './styles'
import {Dialog, DialogTitle, DialogContent, IconButton} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

// App Imports
import { getDashboardCounts } from '../api/actions/query'
import Section from '../../common/Section'
import Loading from '../../common/Loading'
import params from '../../../setup/config/params'
import DashboardCharts from '../Charts/DashboardChart'

// Component
class Dashboard extends PureComponent {
  state = {
    openChart: null,
  }

  componentDidMount() {
    this.refresh()()
  }

  refresh = () => async () => {
    const { getDashboardCounts } = this.props

    await getDashboardCounts()
  }

  handleOpenChart = (chartType) => {
    this.setState({ openChart: chartType })
  }

  handleCloseChart = () => {
    this.setState({ openChart: null })
  }

  renderChartDialog() {
    const { openChart } = this.state
    const { dashboardCounts: { counts } } = this.props
    
    return (
      <Dialog open={!!openChart} onClose={this.handleCloseChart} maxWidth="md" fullWidth>
        <DialogTitle>
          {openChart === 'orders' && 'Order Chart'}
          {openChart === 'payments' && 'Payments Chart'}
          {openChart === 'customers' && 'New Customers Chart'}
          <IconButton
            aria-label="close"
            onClick={this.handleCloseChart}
            sx={{ position: 'absolute', right: 10, top: 10 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DashboardCharts chartType={openChart} counts={counts} />
        </DialogContent>
      </Dialog>
    )
  }

  render() {
    const { auth: { user }, dashboardCounts: { isLoading, counts }, classes } = this.props

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            Dashboard
          </Typography>

          <Button onClick={this.refresh()}>
            Refresh
          </Button>
        </Toolbar>
        <Section>
          {
            isLoading
             ? <Loading />
            : (
            <Grid container spacing={3}>
              {/* Orders */}
              <Grid item>
                <Card style={{ minWidth: 120, cursor: 'pointer', borderBottom: '4px solid #2894f4' }} onClick={() => this.handleOpenChart('orders')}>
                  <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Orders
                    </Typography>
                    <Typography variant="h2">
                      {  counts.order.length}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* Payments */}
              <Grid item>
                <Card style={{ minWidth: 120, cursor: 'pointer', borderBottom: '4px solid #2894f4' }} onClick={() => this.handleOpenChart('payments')}>
                  <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Payments
                    </Typography>
                    <Typography variant="h2">
                      {counts.payment ? counts.payment.length : 0}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* Products */}
              <Grid item>
                <Card style={{ minWidth: 120 }}>
                  <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Products
                    </Typography>
                    <Typography variant="h2">
                      { counts.product || 0}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {
                user.role === params.user.roles.admin.key &&
                <>
                  {/* Customers */}
                  <Grid item>
                    <Card style={{ minWidth: 120, cursor: 'pointer', borderBottom: '4px solid #2894f4' }} onClick={() => this.handleOpenChart('customers')}>
                      <CardContent>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Customers
                        </Typography>
                        <Typography variant="h2">
                          {  counts.customer.length}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* Vendors */}
                  <Grid item>
                    <Card style={{ minWidth: 120 }}>
                      <CardContent>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Vendors
                        </Typography>
                        <Typography variant="h2">
                          { counts.vendor }
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* Categories */}
                  <Grid item>
                    <Card style={{ minWidth: 120 }}>
                      <CardContent>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Categories
                        </Typography>
                        <Typography variant="h2">
                          { counts.category }
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </>
              }
            </Grid>
          )}
        </Section>
        {this.renderChartDialog()}
      </div>
    )
  }
}

// Component Properties
Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  dashboardCounts: PropTypes.object.isRequired,
  getDashboardCounts: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

// Component State
function dashboardState(state) {
  return {
    auth: state.auth,
    dashboardCounts: state.dashboardCounts
  }
}

export default connect(dashboardState, { getDashboardCounts })(withStyles(styles)(Dashboard))
