// Imports
import { withStyles, Dialog, DialogContent, Tooltip } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import { Send, FileCopy } from "@mui/icons-material";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { messageShow } from "../../common/api/actions";
import SectionPaper from "../../common/SectionPaper";
import PropTypes from "prop-types";
import Loading from "../../common/Loading";
import { remove, createGoals } from "../api/actions/mutation";
import styles from "./styles";
import dateFormat from "date-fns/format";
import dateParseISO from "date-fns/parseISO";
import { listGoals } from "../api/actions/query";
import { getImageSource } from "../api/routes";
import routes from '../api/routes';
import Image from "../../common/Image";
import CreateEdit from "../../marketing/CreateEdit";
import { Pagination, IconButton } from '@mui/material';
import Checkbox from "@mui/material/Checkbox";
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'

// Component
class Goals extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      count: 0,
      currentPage: 1,
      goalsPerPage: 10,
      sortDirection: null,
      sortedGoals: props.goals,
    };
  }

  handlePageChange = (event, pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  handleSortByExpiryDate = () => {
    const { sortDirection } = this.state;
    const { goals } = this.props; 
    let newDirection = 'asc';

    if (sortDirection === 'asc') {
      newDirection = 'desc';
    }

    const sortedGoals = [...goals].sort((a, b) => {
      const dateA = new Date(a.expiryDate);
      const dateB = new Date(b.expiryDate);

      return newDirection === 'asc' ? dateA - dateB : dateB - dateA;
    });

    this.setState({
      sortedGoals, 
      sortDirection: newDirection,
      currentPage: 1, 
    });
  };
  
  renderSortArrow = () => {
    const { sortDirection } = this.state;
    if (!sortDirection) return null;

    const arrowStyle = {
      color: '#007BFF'
    };

    return (
      <span style={arrowStyle}>
        {sortDirection === 'asc' ? '▲' : '▼'}
      </span>
    );
  };


  isValidDate = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize the time portion
    return date >= today; // Check if the date is today or in the future
  };

  handleDateChange = (dateType, date) => {
    if (!this.isValidDate(date)) {
      alert("Please select a current or future date.");
      return;
    }

    this.setState({ [dateType]: date });
  };


  handleClone = async (goal) => {
    const { createGoals, messageShow, goals, refresh } = this.props;

    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 30);
    const formattedExpiryDate = expiryDate.toISOString().split('T')[0];

    const clonedGoal = {
      ...goal,
      goalName: `${goal.goalName} (Clone)`,
      startDate: new Date().toISOString().split('T')[0],
      expiryDate: formattedExpiryDate,
    };
    try {
      const { data } = await createGoals(clonedGoal);
      if (data.success) {
        this.setState((prevState) => ({
          goals: [...(goals || []), data.data], 
        }));
        refresh();
        messageShow('Goal cloned successfully!');
      } else {
        messageShow(data.message);
      }
    } catch (error) {
      console.error('Error cloning goal:', error);
      messageShow('Error cloning goal. Please try again.');
    }
  };

  handleSelectGoal = (goalId) => {
    this.props.onSelectGoal(goalId); 
  };

  handleSelectAllGoals = () => {
    this.props.onSelectAllGoals(this.state.goals);
  };
  
  isGoalExpired = (goal) => {
    return goal.isExpired;
  };

  render() {
    const { isLoading, currentPage, goalsPerPage, sortedGoals } = this.state;
    const { goals, selectedGoals, popUp, openPopUp, closePopUp, selectedGoalId } = this.props;
    const columnCount = 11; // Number of columns in the table

    const indexOfLastGoal = currentPage * goalsPerPage;
    const indexOfFirstGoal = indexOfLastGoal - goalsPerPage;
    const currentGoals = sortedGoals.slice(indexOfFirstGoal, indexOfLastGoal);

    const totalPages = Math.ceil(sortedGoals.length / goalsPerPage);
    const enabledGoals = goals.filter(goal => !this.isGoalExpired(goal));
    const isAllSelected = enabledGoals.length > 0 && 
        enabledGoals.length === Object.keys(selectedGoals).filter(id => !this.isGoalExpired(goals.find(goal => goal._id === id))).length;
    return (
      <div>
        <SectionPaper>
          <Table padding={"normal"}>
            <TableHead>
              <TableRow>
                <TableCell width={50}>
                  <Checkbox
                    checked={isAllSelected}
                    onChange={this.handleSelectAllGoals}
                  />

                </TableCell>
                <TableCell width={100}>Image</TableCell>
                <TableCell width={150}>Goal name</TableCell>
                <TableCell width={160}>Description</TableCell>
                <TableCell width={150}>Event name</TableCell>
                <TableCell width={150}>Minimum Order Delivery</TableCell>
                <TableCell width={150}>Multiplier event</TableCell>
                <TableCell width={180}>Reward points </TableCell>
                <TableCell width={140}>Start date </TableCell>
                <TableCell width={140} onClick={this.handleSortByExpiryDate} style={{ cursor: 'pointer' }}>Expiry date {this.renderSortArrow()}</TableCell>
                <TableCell width={180} align="center">
                  Actions </TableCell>
              </TableRow>
            </TableHead>

            {isLoading ? (
              <Loading />
            ) : sortedGoals.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={columnCount} style={{
                    textAlign: 'center',
                    padding: '25px',
                    fontSize: '20px',
                    variant: 'h6',
                  }}>
                    No Goals Found
                  </TableCell>
                  {/* <EmptyMessage message={" No products found"} /> */}
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>

                {currentGoals.map(({ _id, image, goalName, description, eventName, operationName, minOrderValue, multiplierEvent, rewardPoints, startDate, expiryDate, expiryDateTime, isExpired }) => {
                  const isDisabled = this.isGoalExpired({ isExpired }); 
                  return (
                  <TableRow key={_id} hover>
                    <TableCell>
                      <Checkbox
                        checked={!!selectedGoals[_id]}
                        onChange={(e) => {
                          e.stopPropagation(); 
                          if (!isDisabled) this.handleSelectGoal(_id);
                        }}
                        disabled={isDisabled}
                      />
                    </TableCell>
                    <TableCell>
                      <Image
                        src={getImageSource(`${image}`)}
                        defaultSrc={getImageSource()}
                      />
                    </TableCell>
                    <TableCell>{goalName}</TableCell>
                    <TableCell>{description}</TableCell>
                    <TableCell>{Array.isArray(eventName) ? eventName.join(", ") : eventName}</TableCell>
                    <TableCell>{minOrderValue}</TableCell>
                    <TableCell>{multiplierEvent}</TableCell>
                    <TableCell>{rewardPoints}</TableCell>
                    <TableCell>{dateFormat(dateParseISO(startDate), 'yyyy-MM-dd')}</TableCell>
                    <TableCell>{dateFormat(dateParseISO(expiryDate), 'yyyy-MM-dd')}</TableCell>
                    <TableCell>
                      <div style={{ width: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Tooltip title="Edit" arrow>
                            <Button color="primary" component={Link} to={routes.editGoals.path(_id)}><EditIcon /></Button>
                        </Tooltip>
                        <Tooltip title="Create notification">
                          <IconButton color="primary" onClick={(e) => { e.stopPropagation(); this.props.openPopUp(_id); }} disabled={isDisabled}>
                            <Send />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Clone">
                          <span>
                            <IconButton
                              aria-label="clone"
                              size="medium"
                              onClick={(e) => { 
                                e.stopPropagation(); 
                                this.handleClone({ _id, image, goalName, description, eventName, operationName, minOrderValue, multiplierEvent, rewardPoints, startDate, expiryDate }); 
                              }}
                            >
                              <FileCopy color="primary" />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
              </TableBody>
            )}

          </Table>
             {sortedGoals.length > goalsPerPage && (
            <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={this.handlePageChange}
                color="primary"
                siblingCount={1}
                boundaryCount={1}
              />
            </div>
          )}
        </SectionPaper>
        <Dialog open={popUp} onClose={closePopUp} fullWidth>
          <DialogContent>
            <CreateEdit
              onClose={closePopUp}
              rewardData={{ ...sortedGoals.find(goal => goal._id === selectedGoalId), type: "goal" }}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
Goals.propTypes = {
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  createGoals: PropTypes.func.isRequired,
};
function listState(state) {
  return {
    goalsParent: state.goalsParent,
  };
}

export default connect(listState, {
  listGoals,
  remove,
  messageShow,
  createGoals,
})(withStyles(styles)(Goals));
