import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchAnalyticsData } from '../api/action/query';
import { runForecastOrCltvModel } from '../api/action/mutation';
import { messageShow } from '../../common/api/actions';
import Loading from "../../common/Loading"
import AnalyticsChart from './AnalyticsChart';
import { IconButton, Tooltip } from '@mui/material';
import { PlayCircleOutline } from '@mui/icons-material';

const ForecastAndCltv = ({ type, chartType }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
  }, [type, dispatch, chartType]);

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetchAnalyticsData(type);
        if (response.data && response.data.output) {
          setData(response.data.output);
        } else {
          setData([]);
          dispatch(messageShow(`Failed to fetch ${chartType} data`));
        }
      } catch (error) {
        setData([]);
        dispatch(messageShow(`An error occurred while fetching ${chartType} data`));
      } finally {
        setIsLoading(false);
      }
    };

  const handleRunClick = async (type) => {
    try {
      // const response = await runForecastOrCltvModel(type);
      // if (response.status === "success") {
      //   dispatch(messageShow(`${chartType} run completed successfully`));
      // } else {
      //   console.log(response);
      //   dispatch(messageShow(response.error));
      // }
      dispatch(messageShow(`${chartType} yet to be implemented`));
    } catch (error) {
      console.error(`Error running ${chartType}:`, error);
      dispatch(messageShow(`Error running ${chartType}`));
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Tooltip title="Run">
          <IconButton
            size="large"
            variant="contained"
            color="primary"
            onClick={() => handleRunClick(type)}
          >
            <PlayCircleOutline fontSize="medium" />
          </IconButton>
        </Tooltip>
      </div>
      {data.length > 0 ? (
        <AnalyticsChart data={data} name={chartType} />
      ) : (
        <>No data available for {chartType}</>
      )}
    </div>
  );
};

export default ForecastAndCltv;
