import React, { useState, useEffect } from 'react'
import Chart from 'react-apexcharts'
import { FormControl, FormControlLabel, RadioGroup, Radio, Paper, Grid, Pagination } from '@mui/material'
import Loading from '../../common/Loading'

const DashboardCharts = ({ chartType, counts }) => {
  const [chartData, setChartData] = useState(null)
  const [timeFrame, setTimeFrame] = useState('monthly')
  const [currentPage, setCurrentPage] = useState(1)
  const dataPerPage = 15

  useEffect(() => {
    if (counts) {
      setChartData(processData(counts))
    }
    setCurrentPage(1)
  }, [counts, timeFrame, chartType])

  const processData = (data) => {
    const processed = {
      payments: { monthly: {}, weekly: {} },
      orders: { monthly: {}, weekly: {} },
      users: { monthly: {}, weekly: {} }
    }

    if (data && data.payment) {
    data.payment.forEach(payment => {
      const date = new Date(payment.createdAt)
      const month = date.toLocaleString('default', { month: 'short', year: 'numeric' })
      const week = `W${getWeekNumber(date)} - ${date.getFullYear()}`
      const type = payment.type === 'Square' ? 'Square' : 'Store'

      if (!processed.payments.monthly[month]) processed.payments.monthly[month] = { Square: 0, Store: 0 }
      if (!processed.payments.weekly[week]) processed.payments.weekly[week] = { Square: 0, Store: 0 }
      processed.payments.monthly[month][type]++
      processed.payments.weekly[week][type]++
    })
    }

    if (data && data.order) {
    data.order.forEach(order => {
      const date = new Date(order.createdAt)
      const month = date.toLocaleString('default', { month: 'short', year: 'numeric' })
      const week = `W${getWeekNumber(date)} - ${date.getFullYear()}`

      if (!processed.orders.monthly[month]) processed.orders.monthly[month] = 0
      if (!processed.orders.weekly[week]) processed.orders.weekly[week] = 0
      processed.orders.monthly[month]++
      processed.orders.weekly[week]++
    })
    }

    if (data && data.customer) {
    data.customer.forEach(user => {
      const date = new Date(user.createdAt)
      const month = date.toLocaleString('default', { month: 'short', year: 'numeric' })
      const week = `W${getWeekNumber(date)} - ${date.getFullYear()}`

      if (!processed.users.monthly[month]) processed.users.monthly[month] = 0
      if (!processed.users.weekly[week]) processed.users.weekly[week] = 0
      processed.users.monthly[month]++
      processed.users.weekly[week]++
    })
    }

    return processed
  }

  const getWeekNumber = (date)=>{
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7))
    const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1))
    return Math.ceil((((date - yearStart) / 86400000) + 1) / 7)
  }

  const handleTimeFrameChange = (event) => {
    setTimeFrame(event.target.value)
    setCurrentPage(1)
  }

  if (!chartData) {
    return <Loading />
  }
  const sortCategories = (categories) => {
    return categories.sort((a, b) => {
      if (timeFrame === 'weekly') {
        const [weekA,yearA] = a.split(' - ')
        const [weekB,yearB] = b.split(' - ')
        const yearDiff = parseInt(yearA) - parseInt(yearB)
        return yearDiff !== 0 ? yearDiff : parseInt(weekA.slice(1)) - parseInt(weekB.slice(1))
      } else {
        const [monthA, yearA] = a.split(' ')
        const [monthB, yearB] = b.split(' ')
        const dateA = new Date(`${monthA} 1, ${yearA}`)
        const dateB = new Date(`${monthB} 1, ${yearB}`)
        
        return dateA - dateB
      }
    })
  }

  const pagination = (data) => {
    if (!data) return { paginatedCategories: [], allCategories: [] }
    const sortedCategories = sortCategories(Object.keys(data))
    const startIndex = (currentPage - 1) * dataPerPage
    const endIndex = startIndex + dataPerPage
    return {
      paginatedCategories: sortedCategories.slice(startIndex, endIndex),
      allCategories: sortedCategories
    }
  }

  const getMaxValue = (data) => {
    return Math.max(...Object.values(data).map(d => typeof d === 'object' ? Math.max(...Object.values(d)) : d))
  }

  const paymentChartOptions = {
    chart: {
      type: 'bar',
      stacked: false,
    },
    xaxis: {
      categories: pagination(chartData.payments[timeFrame]).paginatedCategories,
    },
    yaxis: {
      title: {
        text: 'Count',
      },
      tickAmount: Math.min(10, Math.ceil(getMaxValue(chartData.payments[timeFrame]) / 10)),
      max: Math.ceil(getMaxValue(chartData.payments[timeFrame]) / 10) * 10,
    },
    title: {
      text: `Square Pay vs Store Pay (${timeFrame.charAt(0).toUpperCase() + timeFrame.slice(1)})`,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return `${y}`;
          }
          return y;
        }
      }
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top',
        },
      }
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ["#304758"]
      }
    },
  }

  const paymentChartSeries = [
    {
      name: 'Square Pay',
      data: pagination(chartData.payments[timeFrame]).paginatedCategories.map(key => chartData.payments[timeFrame][key].Square),
    },
    {
      name: 'Store',
      data: pagination(chartData.payments[timeFrame]).paginatedCategories.map(key => chartData.payments[timeFrame][key].Store),
    },
  ]

  const orderChartOptions = {
    chart: {
      type: 'bar',
    },
    xaxis: {
      categories: pagination(chartData.orders[timeFrame]).paginatedCategories,
    },
    yaxis: {
      title: {
        text: 'Order',
      },
      tickAmount: Math.min(10, Math.ceil(getMaxValue(chartData.orders[timeFrame]) / 10)),
      max: Math.ceil(getMaxValue(chartData.orders[timeFrame]) / 10) * 10,
    },
    title: {
      text: `Orders (${timeFrame.charAt(0).toUpperCase() + timeFrame.slice(1)})`,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return `${y}`;
          }
          return y;
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ["#304758"]
      }
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top',
        },
      }
    },
  }

  const orderChartSeries = [
    {
      name: 'Orders',
      data: pagination(chartData.orders[timeFrame]).paginatedCategories.map(key => chartData.orders[timeFrame][key]),
    },
  ]

  const userChartOptions = {
    chart: {
      type: 'bar',
    },
    xaxis: {
      categories: pagination(chartData.users[timeFrame]).paginatedCategories,
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: false,
        hideOverlappingLabels: true,
        trim: true,
      },
    },
    yaxis: {
      title: {
        text: 'New Customers',
      },
      tickAmount: Math.min(10, Math.ceil(getMaxValue(chartData.users[timeFrame]) / 10)),
      max: Math.ceil(getMaxValue(chartData.users[timeFrame]) / 10) * 10,
    },
    title: {
      text: `New Customers (${timeFrame.charAt(0).toUpperCase() + timeFrame.slice(1)})`,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return `${y}`;
          }
          return y;
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ["#304758"]
      }
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top',
        },
      }
    },
  }

  const userChartSeries = [
    {
      name: 'New Customers',
      data: pagination(chartData.users[timeFrame]).paginatedCategories.map(key => chartData.users[timeFrame][key]),
    },
  ]

  const renderChart = () => {
    switch (chartType) {
      case 'orders':
        return <Chart options={orderChartOptions} series={orderChartSeries} type="bar" height={350} />
      case 'payments':
        return <Chart options={paymentChartOptions} series={paymentChartSeries} type="bar" height={350} />
      case 'customers':
        return <Chart options={userChartOptions} series={userChartSeries} type="bar" height={350} />
      default:
        return null
    }
  }
  
  const getTotalPages = () => {
    if (!chartData) return 1
    let typeOfData
    if (chartType === 'customers') typeOfData = chartData.users[timeFrame]
    else if (chartType === 'orders') typeOfData = chartData.orders[timeFrame]
    else if (chartType === 'payments') typeOfData = chartData.payments[timeFrame]
    if (!typeOfData) return 1
    const totalCategories = Object.keys(typeOfData).length
    return Math.max(1, Math.ceil(totalCategories / dataPerPage));
  }

  const totalPages = getTotalPages();

  return (
    <Paper elevation={4} style={{ padding: '20px' }}>
      <FormControl component="fieldset">
        <RadioGroup row value={timeFrame} onChange={handleTimeFrameChange}>
          <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
          <FormControlLabel value="weekly" control={<Radio />} label="Weekly" />
        </RadioGroup>
      </FormControl>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {renderChart()}
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '-30px' }}>
          <Pagination 
            count={totalPages} 
            page={currentPage} 
            onChange={(event, value) => setCurrentPage(value)} 
            color="primary" 
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default DashboardCharts