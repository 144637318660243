// Imports
import { withStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button'
import Checkbox from "@mui/material/Checkbox"
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from "@mui/icons-material/Add";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel'
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search'
import { IconButton } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
// UI Imports
import Toolbar from "@material-ui/core/Toolbar"
import Tooltip from '@material-ui/core/Tooltip'
import Typography from "@material-ui/core/Typography"
import dateFormat from "date-fns/format"
import dateParseISO from "date-fns/parseISO"
import debounce from "lodash/debounce"
import PropTypes from "prop-types"
import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
// App Imports
import params from "../../../setup/config/params";
import { subString } from "../../../setup/helpers";
import { messageShow } from "../../common/api/actions";
import EmptyMessage from "../../common/EmptyMessage";
import Image from "../../common/Image";
import Loading from "../../common/Loading";
import SectionPaper from "../../common/SectionPaper";
import {
  featureToggle,
  publishToggle,
  remove,
  productPurchase,
  onResetStocks,
  updateLiveCounterItems,
} from "../api/actions/mutation";
import {
  list,
  productCollection,
  productCollectionAll,
} from "../api/actions/query";
import routes, { getImageSource } from "../api/routes";
import styles from "./styles";
import {  Download, RestartAlt } from "@mui/icons-material";
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import Box from '@mui/material/Box';

class List extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      products: [],
      productsAll: [],
      count: 0,
      countAll: 0,
      productId: "",
      purchaseCount: 0,
      purchaseShow: false,
      page: props.match.params.page || 1,
      search: "",
      searchType: "all",
      categorysearch: "",
      descriptionsearch: "",
      isFeaturedsearch: false,
      isPublishedsearch: false,
      isLiveCounterItemsearch: false,
      isStocksearch: false,
      anchorEl: null,
      hederMenuEl: null,
      filtersApplied: false,
      showFilters: false,
      buttonVariant: 'text',
      filterValues: {
        isFeaturedsearch: false,
        isPublishedsearch: false,
        isLiveCounterItemsearch: false,
        isStocksearch: false,
      },
      originalProducts: [],
      filteredProducts: [],
      currentPage: 1,
      itemsPerPage: 20, 
      filteredCount: 0,
      showPagination: false,
      sortBy: 'updatedAt', 
      sortOrder: 'desc', 
    };

    this.changeSearch = debounce(this.refresh, 100);
  }
  handleClose = () =>
    this.setState({
      purchaseCount: 0,
      purchaseShow: false,
      showPurchaseHistory: false,
    });
  handleShow = (_id) => {
    this.setState({ productId: _id, purchaseCount: 0, purchaseShow: true });
    this.menuClose();
  };
  handleShowHistory = (_id) => {
    this.setState({
      purchases: (
        this.state.products.find((product) => product._id === _id) || {
          purchases: [],
        }
      ).purchases,
      showPurchaseHistory: true,
    });
    this.menuClose();
  };
  onTypePopup = ({ target: { value } }) => {
    this.setState({ purchaseCount: value });
  };
  updatePurchaseCount = async (event) => {
    let { purchaseCount } = this.state;
    this.setState({ purchaseShow: false });
    const { productPurchase, messageShow } = this.props;
    await productPurchase({
      purchaseCount: purchaseCount,
      productId: this.state.productId,
    });
    messageShow("Purchase count added successfully");
  };
  componentDidMount() {
    this.updateButtonVariant();
    this.refresh();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { match, products, filterValues } = nextProps;

    // Update page state if the page parameter in the URL has changed
    if (this.props.match.params.page !== match.params.page) {
        this.setState({ page: match.params.page }, this.refresh);
    }

    // Update originalProducts if the product list has changed
    if (this.props.products !== products) {
        this.setState({ originalProducts: products });
    }

    // Check if filters have changed
    if (this.props.filterValues !== filterValues) {
        this.setState({ filtersApplied: true }, () => {
            this.refresh(); 
        });
    }
}


    componentDidUpdate(prevProps) {
      const { match, products, filterValues } = this.props;
  
      // Update page state if the page parameter in the URL has changed
      if (prevProps.match.params.page !== match.params.page) {
          this.setState({ page: match.params.page }, this.refresh);
      }
  
      // Update originalProducts if the product list has changed
      if (prevProps.products !== products) {
          this.setState({ originalProducts: products });
      }
  
      // Check if filters have changed
      if (prevProps.filterValues !== filterValues) {
          this.setState({ filtersApplied: true }, this.refresh); 
      }
  }
  
  handleMenuClick = (e) => {
    if (e && e.currentTarget) {
      this.setState({ anchorEl: e.currentTarget });
    }
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  updateButtonVariant = () => {
    const { isFeaturedsearch, isPublishedsearch, isLiveCounterItemsearch, isStocksearch } = this.state;
    const buttonVariant = (isFeaturedsearch || isPublishedsearch || isLiveCounterItemsearch || isStocksearch) ? 'contained' : 'text';
    this.setState({ buttonVariant });
  };
    
  filterProducts = async () => {
    const { originalProducts, search, descriptionsearch, categorysearch, searchType, filterValues } = this.state;
    let filteredProducts = originalProducts;
  
    const normalizedSearch = 
      searchType === 'name' ? search.replace(/\s+/g, '').toLowerCase() :
      searchType === 'category' ? categorysearch.replace(/\s+/g, '').toLowerCase() :
      searchType === 'description' ? descriptionsearch.replace(/\s+/g, '').toLowerCase() : '';
  
    if (searchType !== 'all') {
      filteredProducts = originalProducts.filter(product => {
        const matchesSearch =
          searchType === 'name'
            ? product.name.replace(/\s+/g, '').toLowerCase().includes(normalizedSearch)
            : searchType === 'category'
            ? product.categoryId.name.replace(/\s+/g, '').toLowerCase().includes(normalizedSearch)
            : searchType === 'description'
            ? product.description.replace(/\s+/g, '').toLowerCase().includes(normalizedSearch)
            : true;
  
        const matchesFilters =
          (filterValues.isFeaturedsearch ? product.isFeatured : true) &&
          (filterValues.isPublishedsearch ? product.isPublished : true) &&
          (filterValues.isLiveCounterItemsearch ? product.isLiveCounterItem : true) &&
          (filterValues.isStocksearch ? product.isStock : true);
  
        return matchesSearch && matchesFilters;
      });
    } else {
      filteredProducts = originalProducts;
    }
  
    this.sortAndSetProducts(filteredProducts);
  };
  
  
  sortAndSetProducts = (products) => {
    const { sortBy, sortOrder } = this.state;
    
    const sortedProducts = [...products].sort((a, b) => {
      let aValue, bValue;
 
      // Handle sorting for different fields
      switch (sortBy) {
        case 'name':
          aValue = a.name;
          bValue = b.name;
          break;
        case 'category':
          aValue = a.categoryId?.name;  // If categoryId is an object
          bValue = b.categoryId?.name;
          break;
        case 'updatedAt':
          aValue = new Date(a.updatedAt);  // If updatedAt is a date
          bValue = new Date(b.updatedAt);
          break;
        default:
          aValue = a[sortBy];
          bValue = b[sortBy];
          break;
      }
 
      // Handle ascending/descending order
      if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });
 
    const filteredCount = sortedProducts.length;
 
    this.setState({
      filteredProducts: sortedProducts,
      filteredCount,
      showPagination: filteredCount > this.state.itemsPerPage,
      currentPage: 1,
      products: sortedProducts.slice(0, this.state.itemsPerPage),
    }, () => {
      this.handlePageChange(1);  // Update pagination when sorting changes
    });
 };
 
handlePageChange = (newPage) => {
  const { filteredProducts, itemsPerPage } = this.state;
  const indexOfLastItem = newPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);

  this.setState({
    currentPage: newPage,
    products: currentItems, 
  }, () => {
    window.history.pushState(null, '', `/products/${newPage}`);
  });
};

renderPagination = () => {
const { filteredCount, currentPage, itemsPerPage } = this.state;
const totalPages = Math.ceil(filteredCount / itemsPerPage);

if (totalPages <= 1) return null; 

return (
  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
    {Array.from({ length: totalPages }, (_, index) => (
      <button
        key={index + 1}
        onClick={() => this.handlePageChange(index + 1)}
        disabled={currentPage === index + 1}
        style={{
          padding: '4px 25px',
          //margin: '0 5px',
          backgroundColor: currentPage === index + 1 ? '#00A4EF' : 'transparent',
          color: currentPage === index + 1 ? '#fff' : '#00A4EF',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          transition: 'background-color 0.3s, color 0.3s',
          fontWeight: currentPage === index + 1 ? 'normal' : 'normal',
          fontSize: '0.85rem',
          boxShadow: currentPage === index + 1 ? '0 4px 8px rgba(0, 164, 239, 0.5)' : 'none',
        }}
      >
        {index + 1}
      </button>
    ))}
  </div>
);
};


handleSearch = () => {
    this.filterProducts(); 
};

  
  applyFilters = () => {
    const { originalProducts, filterValues } = this.state;
  
    const filteredProducts = originalProducts.filter(product => {
      return (
        (filterValues.isFeaturedsearch ? product.isFeatured : true) &&
        (filterValues.isPublishedsearch ? product.isPublished : true) &&
        (filterValues.isLiveCounterItemsearch ? product.isLiveCounterItem : true) &&
        (filterValues.isStocksearch ? product.isStock : true)
      );
    });
  
    const filteredCount = filteredProducts.length;
  
    this.setState({ 
      products: filteredProducts.slice(0, this.state.itemsPerPage),
      filteredProducts,
      filteredCount,
      showPagination: filteredCount > this.state.itemsPerPage, 
      currentPage: 1 
    });
  };
  
  clearFilters = () => {
  this.setState(prevState => ({
    filterValues: {
      isFeaturedsearch: false,
      isPublishedsearch: false,
      isLiveCounterItemsearch: false,
      isStocksearch: false,
    },
    products: prevState.originalProducts, 
    filteredCount: prevState.originalProducts.length, 
    currentPage: 1, 
    showPagination: prevState.originalProducts.length > this.state.itemsPerPage 
  }), () => {
    this.updateButtonVariant(); 
    this.filterProducts(); 
  });
};

  
  handleButtonClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  
  handleCheckboxChange = (stateProp) => (event) => {
    this.setState(prevState => {
      const newFilterValues = {
        ...prevState.filterValues,
        [stateProp]: event.target.checked,
      };
  
      const buttonVariant = (newFilterValues.isFeaturedsearch || newFilterValues.isPublishedsearch || newFilterValues.isLiveCounterItemsearch || newFilterValues.isStocksearch) ? 'contained' : 'text';
  
      return {
        filterValues: newFilterValues,
        buttonVariant, 
      };
    });
  };
  
  
  
  refresh = async (isLoading = true) => {
    const { list, messageShow } = this.props;
    const { page, search, searchType, categorysearch, descriptionsearch, isLiveCounterItemsearch, isFeaturedsearch, isPublishedsearch, isStocksearch } = this.state;
  
    this.isLoadingToggle(isLoading);
  
    try {
      const filter = {
        search: searchType === 'product' ? search : '',
        categorysearch: searchType === 'category' ? search : categorysearch,
        descriptionsearch: searchType === 'description' ? search : descriptionsearch,
        isLiveCounterItemsearch,
        isFeaturedsearch,
        isPublishedsearch,
        isStocksearch
      };
      /*if (searchType === 'none') {
        delete filter.search;
        delete filter.categorysearch;
        delete filter.descriptionsearch;
    }*/
  
      const { data } = await list({ page, filter, listAll: true });
  
      if (data.success) {
        this.setState({
          products: data.data.list.slice(0, this.state.itemsPerPage),
          originalProducts: data.data.list,  
          filteredProducts: data.data.list, 
          filteredCount: data.data.count,
          showPagination: data.data.count > this.state.itemsPerPage,          
          count: data.data.count,
          filtersApplied: false, 
        });
      } else {
        messageShow(data.message);
        this.setState({ products: [], filteredCount: 0 });
      }
    } catch (error) {
      messageShow("There was some error. Please try again.");
      this.setState({ products: [], filteredCount: 0 });
    } finally {
      this.isLoadingToggle(false);
    }
  };
  
  
  getAllProducts = async (isLoading = true) => {
    this.headerMenuClose();
    const { list, messageShow } = this.props;
    const { page, search,searchType, categorysearch, descriptionsearch, isLiveCounterItemsearch, isFeaturedsearch, isPublishedsearch, isStocksearch } = this.state;
    this.isLoadingToggle(isLoading);

    try {
      const filter = { search, categorysearch, descriptionsearch, isLiveCounterItemsearch, isFeaturedsearch, isPublishedsearch, isStocksearch };

      if (searchType === 'product') filter.search = search;
      if (searchType === 'category') filter.categorysearch = search;
      if (searchType === 'description') filter.descriptionsearch = search;

      const { data } = await list({ page, filter, listAll: true });
      if (data.success) {
        this.setState({
          productsAll: data.data.list,
          countAll: data.data.count,
        });
        this.onAllProductStatement();
      } else {
        messageShow(data.message);
      }
    } catch (error) {
      messageShow("There was some error. Please try again.");
    } finally {
      this.isLoadingToggle(false);
    }
  };

  isLoadingToggle = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  onProductStatement = async () => {
    this.headerMenuClose();
    const { productCollection, messageShow } = this.props;
    const { products } = this.state;

    try {
      const { data } = await productCollection({ products });

      messageShow(data.message);

      if (data.success && data.data) {
        window.open(data.data, "_blank");
      }
    } catch (e) {
      messageShow("There was some error. Please try again.");
    }
  };

  onAllProductStatement = async () => {
    this.headerMenuClose();
    const { productCollectionAll, messageShow } = this.props;
    const { productsAll } = this.state;

    try {
      const { data } = await productCollectionAll({ productsAll });

      messageShow(data.message);

      if (data.success && data.data) {
        window.open(data.data, "_blank");
      }
    } catch (e) {
      messageShow("There was some error. Please try again.");
    }
  };

  onDelete = async (productId) => {
    this.menuClose();
    let check = window.confirm("Are you sure you want to delete this product?");

    if (check) {
      const { remove, messageShow } = this.props;

      try {
        const { data } = await remove({ productId });

        messageShow(data.message);

        if (data.success) {
          await this.refresh(false);
        }
      } catch (error) {
        messageShow("Some error occurred. Please try again.");
      }
    }
  };

  onliveCounterItemToggle = async (event, productId) => {
    const isLiveCounterItem = event.target.checked;

    let check = window.confirm(
      `Are you sure you want to ${isLiveCounterItem ? "Add this Product to live counter item ? " : "Remove this Product from live counter item ?  "
      } `
    );
    if (check) {
      const { updateLiveCounterItems, messageShow } = this.props;
      try {
        const { data } = await updateLiveCounterItems({ productId, isLiveCounterItem });

        messageShow(data.message);

        if (data.success) {
          await this.refresh(false);
        }
      } catch (error) {
        messageShow("Some error occurred. Please try again.");
      }
    }
  };

  onFeatureToggle = async (event, productId) => {
    const isFeatured = event.target.checked;

    let check = window.confirm(
      `Are you sure you want to ${isFeatured ? "feature" : "unfeature"
      } this category?`
    );

    if (check) {
      const { featureToggle, messageShow } = this.props;

      try {
        const { data } = await featureToggle({ productId, isFeatured });

        messageShow(data.message);

        if (data.success) {
          await this.refresh(false);
        }
      } catch (error) {
        messageShow("Some error occurred. Please try again.");
      }
    }
  };

  onPublishToggle = async (event, productId) => {
    const isPublished = event.target.checked;

    let check = window.confirm(
      `Are you sure you want to ${isPublished ? "publish" : "unpublish"
      } this category?`
    );

    if (check) {
      const { publishToggle, messageShow } = this.props;

      try {
        const { data } = await publishToggle({ productId, isPublished });

        messageShow(data.message);

        if (data.success) {
          await this.refresh(false);
        }
      } catch (error) {
        messageShow("Some error occurred. Please try again.");
      }
    }
  };
  
  menuClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  menuClose = () => {
    this.setState({ anchorEl: null });
  };

  headerMenuClick = (event) => {
    this.setState({ hederMenuEl: event.currentTarget });
  };
  headerMenuClose = () => {
    this.setState({ hederMenuEl: null });
  };

  resetStocks = async () => {
    this.headerMenuClose()
    try {
      const { onResetStocks, messageShow } = this.props;
      const result = await onResetStocks();
      messageShow(result?.data?.message);
    } catch (error) {
      messageShow("There was some error. Please try again.");
    }
  };
  
  onChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      if (value === "") {
        this.onSearch({ event, target: { name, value } });
      }
    });
  };

  onSearch = ({ event, target: { name, value } }) => {
    const setSearch = (searchType, searchValue) => {
        this.setState({ [searchType]: searchValue, currentPage: 1 });
    };
    setSearch(name, value);
};

onSearchChange = (event) => {
  const { value } = event.target;
  const { searchType } = this.state;

  if (searchType === 'all') {
    this.setState({
      search: '',
      categorysearch: '',
      descriptionsearch: '',
      currentPage: 1,
    },this.filterProducts() ); 
  } else if (searchType === 'category') {
    this.setState({ categorysearch: value }, () => {
      if (value === "") {
        this.filterProducts();        
      }
    });
  } else if (searchType === 'description') {
    this.setState({ descriptionsearch: value }, () => {
      if (value === "") {
        this.filterProducts();        
      }
    });
  } else {
    this.setState({ search: value }, () => {
      if (value === "") {
        this.filterProducts();        
      }
    });
  }
};

  handleSort = (column) => {
    const { sortBy, sortOrder } = this.state;
    const newSortOrder = sortBy === column && sortOrder === 'asc' ? 'desc' : 'asc';
    this.setState({ sortBy: column, sortOrder: newSortOrder }, this.filterProducts); 
  };
  isSearchValid = () => {
    const { searchType, search, categorysearch, descriptionsearch } = this.state;
  
    if (searchType === 'name' && !search.trim()) {
      return false;
    }
    if (searchType === 'category' && !categorysearch.trim()) {
      return false;
    }
    if (searchType === 'description' && !descriptionsearch.trim()) {
      return false;
    }
      return true;
  };
  
  handleSearchTypeChange = (event) => {
    const newSearchType = event.target.value;
  
    const areFieldsCleared = !this.state.search && !this.state.categorysearch && !this.state.descriptionsearch;
  
    this.setState(
      {
        search: '',  
        categorysearch: '',  
        descriptionsearch: '',  
        searchType: newSearchType 
      },
      () => {
        
        if (!areFieldsCleared) {
          this.filterProducts(); 
        }
      }
    );
  };
  

  render() {
    const {
      auth: { user },
      classes,
    } = this.props;
    const { isLoading, products,searchType,buttonVariant,anchorEl} = this.state;
    const searchOptions = [
      { value: 'all', label: 'All' },
      { value: 'name', label: 'Name' },
      { value: 'category', label: 'Category' },
      { value: 'description', label: 'Description' },
    ];
    const isSearchDisabled = searchType === 'all';
    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit">
            Products
          </Typography>
        </Toolbar>
        <Toolbar className={classes.toolbar}>
          <Grid container spacing={2} alignItems="center" style={{ flexWrap: 'nowrap' }}>
            <Grid item>
              <FormControl variant="outlined" className={classes.searchFormControl}>
                <InputLabel id="search-type-label">Search By</InputLabel>
                <Select
                  labelId="search-type-label"
                  id="search-type"
                  value={searchType}
                  onChange={this.handleSearchTypeChange}  
                  label="Search By"
                  style={{
                    width: '130px', 
                    minWidth:'80px',
                    height: '50px',
                    fontSize: '1rem',
                  }}
                >
                  {searchOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <TextField
                placeholder="Search..."
                value={searchType === 'all' ? '' : (
                  searchType === 'description' ? this.state.descriptionsearch :
                  searchType === 'category' ? this.state.categorysearch :
                  this.state.search
                )}
                onChange={this.onSearchChange}
                className={classes.searchInput}
                disabled={!this.state.searchType || isSearchDisabled}                 
              />
            </Grid>
            <Grid item>
              <Button
              variant="outlined"
              color="primary"
              onClick={this.handleSearch}
              disabled={!this.state.searchType || !this.isSearchValid() || isSearchDisabled} 
              style={{ borderRadius: '5px',
                textTransform: 'none',  
                padding: '2px 13px',     
                fontSize: '0.9rem', 
                }}
              >
               Search
              </Button>
            </Grid>
            <Grid container>
  <Grid item>
    <Button
      variant={buttonVariant} 
      color="primary"
      onClick={this.handleButtonClick} 
      sx={{
        marginLeft: '20%', 
        minWidth: '120px', 
        fontSize: '1.2rem', 
        textTransform: 'none', 
        borderRadius: '5px', 
        '&:hover': buttonVariant === 'text' ? {
          backgroundColor: 'rgba(135, 206, 250, 0.4)', 
        } : {},
        '& .MuiButton-startIcon': {
          fontSize: '1.5rem',
        },
      }}
      startIcon={<FilterListIcon />}
    >
      Filters
    </Button>
  </Grid>
  <Grid item>
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={this.handleMenuClose}
      PaperProps={{
        style: {
          maxHeight: 300,
          width: 250,
        },
      }}
    >
      <MenuItem>
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.filterValues.isFeaturedsearch}
              onChange={this.handleCheckboxChange('isFeaturedsearch')}
            />
          }
          label="Featured"
        />
      </MenuItem>
      <MenuItem>
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.filterValues.isPublishedsearch}
              onChange={this.handleCheckboxChange('isPublishedsearch')}
            />
          }
          label="Published"
        />
      </MenuItem>
      <MenuItem>
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.filterValues.isLiveCounterItemsearch}
              onChange={this.handleCheckboxChange('isLiveCounterItemsearch')}
            />
          }
          label="Live Counter Item"
        />
      </MenuItem>
      <MenuItem>
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.filterValues.isStocksearch}
              onChange={this.handleCheckboxChange('isStocksearch')}
            />
          }
          label="Quantity"
        />
      </MenuItem>
      <MenuItem>
        <Button 
          color="primary"
          variant="contained"
          size="small"
          style={{ 
            marginRight: '8px',
            fontSize: '0.75rem', 
          }} 
          onClick={this.applyFilters}
        >
          Apply
        </Button>
        <Button 
          color="error"
          variant="contained"
          size="small"
          sx={{ fontSize: '0.75rem' }}
          onClick={this.clearFilters}
        >
          Clear
        </Button>
      </MenuItem>
    </Menu>
  </Grid>
</Grid>
            </Grid>

          
          <Link to={routes.productCreate.path}>
            <Tooltip title="Create Product" arrow>
              <Button size="big" color="primary" startIcon={<AddIcon />}></Button>
            </Tooltip>
          </Link>
          <Link to={routes.productBulkCreate.path}>
          <Tooltip title="Bulk Create Product" arrow>
           <Box position="relative" display="inline-flex">
            <FileCopyOutlinedIcon style={{ fontSize: 30 }} />
            <AddIcon
              style={{
                fontSize: 20,
                position: 'absolute',
                top: '60%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
           </Box> 
           </Tooltip>         
          </Link>
            <Tooltip title="Download" arrow>
              <Button
                color="primary"
                id="basic-button"
                size="big"
                aria-controls={
                  Boolean(this.state.hederMenuEl) ? "basic-menu" : undefined
                }
                endIcon={<Download />}
                aria-haspopup="true"
                aria-expanded={Boolean(this.state.hederMenuEl) ? "true" : undefined}
                onClick={this.headerMenuClick}
              >
              </Button>
            </Tooltip>
          <Menu
            id="basic-menu"
            anchorEl={this.state.hederMenuEl}
            open={Boolean(this.state.hederMenuEl)}
            onClose={this.headerMenuClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            elevation={1}
          >
            <MenuItem onClick={this.onProductStatement}><Download style={{ marginRight: 10 }} />
              Download Product</MenuItem>
            <MenuItem onClick={this.getAllProducts}><Download style={{ marginRight: 10 }} />
              Download All Products</MenuItem>
            <MenuItem onClick={this.resetStocks}><RestartAlt style={{ marginRight: 10 }} />Restore Stocks</MenuItem>
          </Menu>
          {/* <Button variant="outlined" onClick={this.onProductStatement}>
            <IconCloudDownload style={{ marginRight: 10 }} />
            Download Product
          </Button> */}
          {/* <Button
            style={{ marginLeft: 2 }}
            variant="outlined"
            onClick={this.getAllProducts}
          >
            <IconCloudDownload style={{ marginRight: 10 }} />
            Download All Products
          </Button> */}
          {/* <Button
            style={{ marginLeft: 2 }}
            variant="outlined"
            onClick={this.resetStocks}
          >
            Reset Stocks
          </Button> */}
        </Toolbar>
        
        <SectionPaper>
          {isLoading ? (
            <Loading />
          ) : products.length === 0 ? (
            <EmptyMessage message={" No products found"} />
          ) : (
            <>
              <Table padding={"normal"}>
                <TableHead>
                  <TableRow>
                    <TableCell width={80}>Image</TableCell>
                    <TableCell width={100} onClick={() => this.handleSort('name')} style={{ cursor: 'pointer' }}>
                      Name 
                      {this.state.sortBy === 'name' ? (
                        <span style={{ color: this.state.sortOrder === 'asc' ? '#00A4EF' : '#00A4EF' }}>
                          {this.state.sortOrder === 'asc' ? '▲' : '▼'}
                        </span>
                      ) : ''}
                    </TableCell>
                    {/*{
                          user.role === params.user.roles.admin.key &&
                          <TableCell>Vendor</TableCell>
                        } */}
                    <TableCell width={120} onClick={() => this.handleSort('category')} style={{ cursor: 'pointer' }}>
                      Category {this.state.sortBy === 'category' ? (
                        <span style={{ color: this.state.sortOrder === 'asc' ? '#00A4EF' : '#00A4EF' }}>
                        {this.state.sortOrder === 'asc' ? '▲' : '▼'}
                      </span>
                    ) : ''}
                    </TableCell>
                    {/* <TableCell>Buys</TableCell> */}
                    {/* <TableCell>Subscribable</TableCell> */}
                    <TableCell width={120} onClick={() => this.handleSort('updatedAt')} style={{ cursor: 'pointer' }}>
                      Updated
                      {this.state.sortBy === 'updatedAt' ? (
                        <span style={{ color: this.state.sortOrder === 'asc' ? '#00A4EF' : '#00A4EF' }}>
                          {this.state.sortOrder === 'asc' ? '▲' : '▼'}
                        </span>
                      ) : ''}
                    </TableCell>

                    <TableCell width={70}>Online Price</TableCell>
                    <TableCell width={60}>
                    {/*<Checkbox
                          name='isStocksearch'
                          checked={isStocksearch}
                          onChange={(event) =>
                          this.oncSearch(event )
                          }
                          color="primary"
                         />*/}
                         Quantity
                    </TableCell>
                    {user.role === params.user.roles.admin.key ? (
                      <>
                      <TableCell width={60} align="center">
                        {/*<Checkbox
                          name='isLiveCounterItemsearch'
                          checked={isLiveCounterItemsearch}
                          onChange={(event) =>
                          this.oncSearch(event)
                          }
                          color="primary"
                         />*/}
                          Live Counter Item
                        </TableCell>
                        <TableCell width={60} align="center">
                          {/*<Checkbox
                            name='isFeaturedsearch'
                            checked={isFeaturedsearch}
                            onChange={(event) =>
                              this.oncSearch(event)
                            }
                            color="primary"
                          />*/}
                          Featured
                        </TableCell>
                         <TableCell width={60} align="center">
                          {/*<Checkbox
                            name='isPublishedsearch'
                            checked={isPublishedsearch}
                            onChange={(event) =>
                              this.oncSearch(event)
                            }
                            color="primary"
                          />*/}
                          Published
                        </TableCell>
                      </>
                    ) : (
                      <TableCell width={60} align="center">
                        Published
                      </TableCell>
                    )}
                    <TableCell
                      width={
                        user.role === params.user.roles.admin.key ? 150 : 120
                      }
                      align="center"
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.map(
                    ({
                      _id,
                      image,
                      name,
                      vendorId,
                      categoryId,
                      price,
                      buyCount,
                      currentStock,
                      canSubscribe,
                      isLiveCounterItem,
                      isFeatured,
                      isPublished,
                      updatedAt,
                    }, prodIndex) => (
                      <TableRow key={_id}  onClick={() => window.location.href = `/product/edit/${ _id }`} style={{ cursor: 'pointer' }} hover>
                        <TableCell>
                          <Link to={routes.productEdit.path(_id)}>
                            <Image
                              src={getImageSource(`${image}`)}
                              defaultSrc={getImageSource()}
                            />
                          </Link>
                        </TableCell>
                        <TableCell>{subString(name, 50)}</TableCell>
                        {/* <TableCell>{subString(name, 30)}</TableCell>
                            
                              user.role === params.user.roles.admin.key &&
                              <TableCell>{vendorId?.name}</TableCell>
                            } */}
                        <TableCell>{categoryId.name}</TableCell>
                        {/* <TableCell>{buyCount || 0}</TableCell> */}
                        {/* <TableCell>{canSubscribe ? 'Yes' : 'No'}</TableCell> */}
                        <TableCell>
                          {dateFormat(
                            dateParseISO(updatedAt),
                            params.date.format.full
                          )}
                        </TableCell>
                        <TableCell >
                          {price ? `$${price.toFixed(2)}` : 'N/A'}
                        </TableCell>
                        <TableCell>{currentStock || 0}</TableCell>
                        {user.role === params.user.roles.admin.key ? (
                          <>
                            <TableCell align="center">
                              <Checkbox
                                onClick={(e) => e.stopPropagation()}
                                checked={isLiveCounterItem}
                                onChange={(event) =>
                                  this.onliveCounterItemToggle(event, _id)
                                }
                              />
                            </TableCell>

                            <TableCell align="center">
                              <Checkbox
                                onClick={(e) => e.stopPropagation()}
                                checked={isFeatured}
                                onChange={(event) =>
                                  this.onFeatureToggle(event, _id)
                                }
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Checkbox
                                onClick={(e) => e.stopPropagation()}
                                checked={isPublished}
                                onChange={(event) =>
                                  this.onPublishToggle(event, _id)
                                }
                                color="primary"
                              />
                            </TableCell>
                          </>
                        ) : (
                          <TableCell align="center">{isPublished ? "Yes" : "No"}</TableCell>
                        )}
                        <TableCell align="center">
                          {/* <Button onClick={() => this.handleShowHistory(_id)}>
                            Purchase Report
                          </Button>
                          <Button onClick={() => this.handleShow(_id)}>
                            Purchase Order
                          </Button> */}
                          {user.role === params.user.roles.admin.key && (
                            <Tooltip title="Delete" arrow>
                              <Button color="error" onClick={(e) => { e.stopPropagation(); this.onDelete(_id); }}>
                                <DeleteIcon /> 
                              </Button>
                            </Tooltip>
                          )}
                          {/* <div>
                            <IconButton
                              size="large"
                              aria-controls={
                                Boolean(this.state.anchorEl)
                                  ? "basic-menu"
                                  : undefined
                              }
                              color="secondary"
                              aria-haspopup="true"
                              aria-expanded={
                                Boolean(this.state.anchorEl)
                                  ? "true"
                                  : undefined
                              }
                              onClick={(prodIndex, event) => this.menuClick(prodIndex, event)}
                            >
                              <MoreVert />
                            </IconButton>
                            <Menu
                              anchorEl={this.state.anchorEl}
                              open={Boolean(this.state.anchorEl)}
                              onClose={this.menuClose}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                              elevation={1}
                            >
                              <MenuItem>{_id}</MenuItem>
                              <MenuItem
                                onClick={() => this.handleShowHistory(_id)}
                              >
                                Purchase Report - {_id}
                              </MenuItem>
                              <MenuItem onClick={() => this.handleShow(_id)}>
                                Purchase Order - {_id}
                              </MenuItem>
                              <MenuItem
                                component={Link}
                                to={routes.productEdit.path(_id)}
                              >
                                Edit - {_id}
                              </MenuItem>
                              {user.role === params.user.roles.admin.key && (
                                <MenuItem onClick={() => this.onDelete(_id)}>
                                  Delete - {_id}
                                </MenuItem>
                              )}
                            </Menu>
                            </div> */}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>

              </Table>

              {this.renderPagination()}
              <Dialog
                onClose={this.handleClose}
                open={this.state.purchaseShow ?? false}
                aria-labelledby="simple-dialog-title"
                fullWidth={true}
              >
                <DialogTitle id="simple-dialog-title">
                  Purchase Addition
                </DialogTitle>
                <DialogContent>
                  <div>
                    <Grid container spacing={10} className={classes.buttonUpload}>
                      <Grid item xs={7}>
                        {/* Input - Unit */}
                        <TextField
                          name={"purchase"}
                          value={this.state.purchaseCount}
                          onChange={this.onTypePopup}
                          label={"Purchase Count"}
                          placeholder={"Purchase Count (eg: 100)"}
                          required={true}
                          type="number"
                          margin={"dense"}
                          autoComplete={"off"}
                        />
                      </Grid>

                      <Grid item xs={4}>
                        {/* Input - Can Subscribe */}
                        <Button
                          variant="primary"
                          onClick={this.updatePurchaseCount}
                        >
                          Purchase
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </DialogContent>
              </Dialog>
              <Dialog
                onClose={this.handleClose}
                open={this.state.showPurchaseHistory ?? false}
                aria-labelledby="simple-dialog-title"
                fullWidth={true}
              >
                <DialogTitle id="simple-dialog-title">Purchase History</DialogTitle>
                <DialogContent>
                  <Table padding="normal">
                    <TableHead>
                      <TableRow>
                        <TableCell>Purchase Count</TableCell>
                        <TableCell>Updated Timestamp</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.purchases &&
                        this.state.purchases.map(
                          ({ purchaseCount, updatedAt }, _id) => (
                            <TableRow key={_id}>
                              <TableCell>{purchaseCount}</TableCell>
                              <TableCell>{updatedAt}</TableCell>
                            </TableRow>
                          )
                        )}
                    </TableBody>
                  </Table>
                </DialogContent>
              </Dialog>
            </>
          )}
        </SectionPaper>
      </div>
    );
  }
}

List.propTypes = {
  auth: PropTypes.object.isRequired,
  list: PropTypes.func.isRequired,
  productCollection: PropTypes.func.isRequired,
  productCollectionAll: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  featureToggle: PropTypes.func.isRequired,
  updateLiveCounterItems: PropTypes.func.isRequired,
  publishToggle: PropTypes.func.isRequired,
  messageShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onResetStocks: PropTypes.func.isRequired,
};

function listState(state) {
  return {
    auth: state.auth,
  };
}

export default connect(listState, {
  list,
  remove,
  productCollection,
  productPurchase,
  productCollectionAll,
  featureToggle,
  updateLiveCounterItems,
  publishToggle,
  messageShow,
  onResetStocks,
})(withStyles(styles)(List))




